import {authHeader} from "../_helpers";

const API_BASE = process.env.API_URL;

export const worksheetService = {
    createWorksheet,
};

function createWorksheet(text, letter_style, paper_style, grade_level) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ text, letter_style, paper_style, grade_level })
    };

    return fetch(API_BASE + '/worksheet', requestOptions)
        .then(response => {
            if (!response.ok) {
                return response.json().then(json => {
                    let message = json.message ? json.message : 'Error generating worksheet.';
                    return Promise.reject(message);
                });
            }
            else {
                return response.blob();
            }
        });
}