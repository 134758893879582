import React, { Component } from "react";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { cartService } from '../_services';
import {PageLoader} from "../PageLoader";

import "../../css/shopping-cart/shopping-cart.css";
import {ErrorPage} from "../ErrorPage";

const STATIC_IMAGES_PATH = process.env.STATIC_IMAGES_PATH;

class ShoppingCartItem extends Component {
    render() {
        const imageFile = this.props.item.images.length > 0 ? this.props.item.images[0].filepath : "logo.png";
        return (
            <div className="shopping-cart-item row">
                <div className="col-md-2">
                    <img className="shopping-cart-item-image" src={STATIC_IMAGES_PATH + imageFile} />
                </div>
                <div className="col-md-4">
                    <span className="shopping-cart-item-title">{this.props.item.title}</span>
                    <span className="shopping-cart-item-subtitle">{this.props.item.subtitle}</span>
                    <span className="shopping-cart-item-description" dangerouslySetInnerHTML={{__html: this.props.item.description}} />
                </div>
                <div className="shopping-cart-col-price col-md-2">
                    <span className="">${this.props.item.price}</span>
                </div>
                <div className="col-md-2">
                    <div className="row shopping-cart-quantity-row">
                        {this.props.displayControls === true &&
                            <div className="shopping-cart-quantity-box">
                                <button className="btn btn-primary-dark shopping-cart-decrease-quantity" onClick={() => {
                                    this.props.onQuantityDecrease(this.props.item);
                                }}>-</button>
                            </div>
                        }
                        <div className="shopping-cart-quantity-box">
                            <span className="shopping-cart-item-quantity">{this.props.item.quantity}</span>
                        </div>
                        {this.props.displayControls === true &&
                            <div className="shopping-cart-quantity-box">
                                <button className="btn btn-primary-dark shopping-cart-increase-quantity" onClick={() => {
                                    this.props.onQuantityIncrease(this.props.item);
                                }}>+</button>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-2">
                    {this.props.displayControls === true &&
                        <button className="btn btn-primary-dark remove-item-button" onClick={() => {
                            this.props.onRemoveItemFromCart(this.props.item);
                        }}><i className="fa fa-trash shopping-cart-trash-icon"/> Remove</button>
                    }
                </div>
            </div>
        )
    }
}

class ShoppingCartPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestFailed: false,
            content: [],
            bundledCartItems: []
        };
        this.refreshCartState = this.refreshCartState.bind(this);
    }

    componentDidMount() {
        this.refreshCartState()
    }

    calculatePrice(cartItems) {
        let price = 0.0;
        cartItems.map(function(item, i){
            price += item.price * item.quantity;
        });
        return parseFloat(price).toFixed(2);
    }

    refreshCartState() {
        cartService.getCartItems()
            .then(cartItems => {
                cartItems = cartService.attachCartQuantities(cartItems);
                this.setState({
                    content: cartItems,
                    bundledCartItems: cartService.extractBundledCartItems(cartItems)
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    errorMessage: error
                })
            });
    }

    render() {
        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage}/>
            )
        }
        if (!this.state.content) {
            return (
                <PageLoader />
            )
        }

        let cartItems = this.state.content;
        let totalPrice = this.calculatePrice(cartItems);

        let handleItemQuantityIncrease = function(item) {
                            item.quantity++;
                            cartService.increaseItemQuantity(item)
                                .then(response => {
                                    this.setState({item: item});
                                    this.refreshCartState();
                                }, error => {
                                    toast.error('Error adding item to cart. Please refresh the page and try again.');
                                });
                        }.bind(this);

        let handleItemQuantityDecrease = function(item) {
                            if (item.quantity > 1) {
                                item.quantity--;
                                cartService.decreaseItemQuantity(item).then(response => {
                                        this.setState({item: item});
                                        this.refreshCartState();
                                    }, error => {
                                        toast.error('Error removing item from cart. Please refresh the page and try again.');
                                    });
                            }
                        }.bind(this);

        let removeItemFromCart = function(item) {
            cartService.removeItemFromCart(item)
                .then(response => {
                    this.refreshCartState();
                }, error => {
                    toast.error('Error removing item from cart. Please refresh the page and try again.');
                });
            }.bind(this);

        let emptyCartClass = cartItems.length > 0 ? 'hide' : '';

        return (
            <div className="shopping-cart-content container">
                <div className="shopping-cart-title row">
                    <span>Shopping Cart</span>
                </div>
                <div className={"shopping-cart-empty row " + emptyCartClass}>
                    <span className="fa-stack fa-2x shopping-cart-empty-icon">
                        <i className="far fa-circle fa-stack-2x"/>
                        <i className="fa fa-shopping-cart fa-stack-1x"/>
                    </span>
                    <span className="shopping-cart-empty-message">Empty Cart</span>
                </div>
                <div className="shopping-cart-table row">
                    {cartItems.map(function(item, i){
                        return (
                            <ShoppingCartItem item={item} onRemoveItemFromCart={removeItemFromCart} onQuantityIncrease={handleItemQuantityIncrease} onQuantityDecrease={handleItemQuantityDecrease} displayControls={true} key={item.id} />
                        );
                    })}
                    {this.state.bundledCartItems.map(function(bundledItem, i){
                        return (
                            <ShoppingCartItem item={bundledItem} onRemoveItemFromCart={removeItemFromCart} onQuantityIncrease={handleItemQuantityIncrease} onQuantityDecrease={handleItemQuantityDecrease} displayControls={false} key={bundledItem.id} />
                        );
                    })}
                </div>
                <div className="shopping-cart-total-price-row row">
                    <span className="shopping-cart-total-price">${totalPrice}</span>
                    <span className="shopping-cart-shipping-disclaimer">&nbsp;+ shipping</span>
                </div>
                <div className="shopping-cart-actions row">
                    <Link to="/products">
                        <button className="btn btn-primary-dark continue-shopping-button">
                            <i className="fa fa-arrow-left arrow-left-icon"/>Continue Shopping
                        </button>
                    </Link>
                    <Link to="/checkout">
                        <button className="btn btn-primary-dark go-to-checkout-button" disabled={cartItems.length === 0}>
                            Proceed to Checkout<i className="fa fa-arrow-right arrow-right-icon"/>
                        </button>
                    </Link>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedShoppingCartPage = connect(mapStateToProps)(ShoppingCartPage);
export { connectedShoppingCartPage as ShoppingCartPage };