// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-biography-row {
    margin-top: 20px !important;
}

.about-image {
    max-width: 100%;
    box-shadow: 3px 3px 8px #cccccc;
}

.about-page-title {
    display: block;
    text-align: left;
    font-size: 48px;
    color: #02254A;
    font-family: "Futura PT Condensed Bold", sans-serif;
}

.about-page-biography {
    display: block;
    font-size: 18px;
    text-align: left;
    font-family: "FF Uberhand Pro Medium", sans-serif;
}

.faq-section-title {
    display: block;
    font-size: 48px;
    color: #02254A;
    text-align: left;
    padding: 15px;
    font-family: "Futura PT Condensed Bold", sans-serif;
}

.faq-row {
    text-align: left;
}

.faq-question {
    display: block;
    color: #02254A;
    font-size: 24px;
    font-family: "Futura PT Condensed Bold", sans-serif;
}

.faq-answer {
    display: block;
    font-size: 18px;
    margin-bottom: 20px;
    font-family: "FF Uberhand Pro Medium", sans-serif;
}`, "",{"version":3,"sources":["webpack://./css/about/about.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,+BAA+B;AACnC;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,mDAAmD;AACvD;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iDAAiD;AACrD;;AAEA;IACI,cAAc;IACd,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,mDAAmD;AACvD;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,cAAc;IACd,eAAe;IACf,mDAAmD;AACvD;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;IACnB,iDAAiD;AACrD","sourcesContent":[".about-biography-row {\n    margin-top: 20px !important;\n}\n\n.about-image {\n    max-width: 100%;\n    box-shadow: 3px 3px 8px #cccccc;\n}\n\n.about-page-title {\n    display: block;\n    text-align: left;\n    font-size: 48px;\n    color: #02254A;\n    font-family: \"Futura PT Condensed Bold\", sans-serif;\n}\n\n.about-page-biography {\n    display: block;\n    font-size: 18px;\n    text-align: left;\n    font-family: \"FF Uberhand Pro Medium\", sans-serif;\n}\n\n.faq-section-title {\n    display: block;\n    font-size: 48px;\n    color: #02254A;\n    text-align: left;\n    padding: 15px;\n    font-family: \"Futura PT Condensed Bold\", sans-serif;\n}\n\n.faq-row {\n    text-align: left;\n}\n\n.faq-question {\n    display: block;\n    color: #02254A;\n    font-size: 24px;\n    font-family: \"Futura PT Condensed Bold\", sans-serif;\n}\n\n.faq-answer {\n    display: block;\n    font-size: 18px;\n    margin-bottom: 20px;\n    font-family: \"FF Uberhand Pro Medium\", sans-serif;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
