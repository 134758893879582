const API_BASE = process.env.API_URL;

export const contactService = {
    sendContactRequest
};

function sendContactRequest(name, email, phone, occupation, message) {
    phone = phone ? phone : 'None';
    occupation = occupation ? occupation : 'None';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({name, email, phone, occupation, message})
    };

    return fetch(API_BASE + '/contact', requestOptions)
        .then(response => {
            if (!response.ok) {
                return response.json().then(json => {
                    let message = json.message ? json.message : 'Error submitting contact request. Please try emailing us directly.';
                    return Promise.reject(message);
                });
            }
            else {
                return response.json();
            }
        });
}