import React, { Component } from "react";
import { connect } from 'react-redux';

import "../../css/page-loader/page-loader.css";

class PageLoader extends Component {
    render() {
        return (
            <div className="row">
                <div className="page-loader-container container">
                    <span>Loading...</span>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedPageLoader = connect(mapStateToProps)(PageLoader);
export { connectedPageLoader as PageLoader };