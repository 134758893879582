// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonials-content {
    font-family: "FF Uberhand Pro Medium", sans-serif;
    margin-bottom: 30px;
}

.testimonials-content .banner-image {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-attachment: inherit;
    background-position-y: 60%;
    opacity: 0.80;
}

.testimonial-row {
    font-size: 20px;
    border-radius: 10px;
    padding: 20px 0 40px;
}

.testimonial-row.text-white {
    color: #fff;
}

.testimonial-row.text-dark-blue {
    color: #20547E;
}

.testimonial-row .testimonial-quote {
    display: block;
}

.testimonial-row .testimonial-author {
    display: block;
    text-align: right;
    padding-top: 30px;
}

.testimonial-row .testimonial-occupation {
    display: block;
    text-align: right;
}`, "",{"version":3,"sources":["webpack://./css/testimonials/testimonials.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;IACjD,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,0BAA0B;IAC1B,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".testimonials-content {\n    font-family: \"FF Uberhand Pro Medium\", sans-serif;\n    margin-bottom: 30px;\n}\n\n.testimonials-content .banner-image {\n    width: 100%;\n    height: 300px;\n    background-size: cover;\n    background-attachment: inherit;\n    background-position-y: 60%;\n    opacity: 0.80;\n}\n\n.testimonial-row {\n    font-size: 20px;\n    border-radius: 10px;\n    padding: 20px 0 40px;\n}\n\n.testimonial-row.text-white {\n    color: #fff;\n}\n\n.testimonial-row.text-dark-blue {\n    color: #20547E;\n}\n\n.testimonial-row .testimonial-quote {\n    display: block;\n}\n\n.testimonial-row .testimonial-author {\n    display: block;\n    text-align: right;\n    padding-top: 30px;\n}\n\n.testimonial-row .testimonial-occupation {\n    display: block;\n    text-align: right;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
