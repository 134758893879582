import React, { Component } from "react";
import { connect } from 'react-redux';
import Slider from "react-slick";

import "../../css/testimonials/slider.css";

class TestimonialSlider extends Component {
    render() {
        const carouselSettings = {
            dots: false,
            autoplay: true,
            fade: true,
            pauseOnHover: false,
            infinite: true,
            speed: 1000,
            autoplaySpeed: 8000,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
        };

        return (
            <div className="row bnm-light-blue testimonial-slider">
                <Slider {...carouselSettings}>
                    {this.props.testimonials.map(function(testimonial, i){
                        return (
                            <div className="testimonial-slider-container" key={i}>
                                <div className="container">
                                    <span className="testimonial-quote" dangerouslySetInnerHTML={{__html: testimonial.quote}} />
                                    <span className="testimonial-slider-author">{testimonial.author}</span>
                                    <span className="testimonial-slider-occupation">{testimonial.occupation}</span>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedTestimonialSlider = connect(mapStateToProps)(TestimonialSlider);
export { connectedTestimonialSlider as TestimonialSlider };