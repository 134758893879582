// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./ConfigPanel.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!./ExportButton.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js!./Page.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.worksheet-maker-breadcrumbs {
    font-size: 22px;
    margin: 10px 0 !important;
    text-align: left;
}

.worksheet-maker {
  background-color: #d6d6d6;
}

.worksheet-maker-container {
    text-align: center;
    padding-bottom: 30px;
}

.worksheet-maker-controls {
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./css/worksheet-maker/worksheet-maker.css"],"names":[],"mappings":"AAIA;IACI,eAAe;IACf,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["@import 'ConfigPanel.css';\n@import 'ExportButton.css';\n@import 'Page.css';\n\n.worksheet-maker-breadcrumbs {\n    font-size: 22px;\n    margin: 10px 0 !important;\n    text-align: left;\n}\n\n.worksheet-maker {\n  background-color: #d6d6d6;\n}\n\n.worksheet-maker-container {\n    text-align: center;\n    padding-bottom: 30px;\n}\n\n.worksheet-maker-controls {\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
