import React, { Component } from "react";
import { connect } from 'react-redux';

import "../../css/subscription-landing/subscription-landing.css";
import {Link} from "react-router-dom";

class SubscriptionsLandingPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="subscription-landing-container container">
                <div className="row">
                    <div className="col-md-4">
                        <Link to="/subscriptions/products" className="subscription-landing-card subscription-landing-card-blue">
                        <i className="fas fa-book bnm-blue-font subscription-landing-icon"/>
                        <span className="subscription-landing-card-label bnm-blue-font">Products</span></Link>
                    </div>
                    <div className="col-md-4">
                        <Link to="/subscriptions/videos" className="subscription-landing-card subscription-landing-card-green">
                        <i className="fas fa-video bnm-green-font subscription-landing-icon"/>
                        <span className="subscription-landing-card-label bnm-green-font">Videos</span></Link>
                    </div>
                    <div className="col-md-4">
                        <Link to="/subscriptions/worksheet-maker" className="subscription-landing-card subscription-landing-card-orange">
                        <i className="fas fa-pencil-alt bnm-orange-font subscription-landing-icon"/>
                        <span className="subscription-landing-card-label bnm-orange-font">Worksheet Maker</span></Link>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedSubscriptionsLandingPage = connect(mapStateToProps)(SubscriptionsLandingPage);
export { connectedSubscriptionsLandingPage as SubscriptionsLandingPage };