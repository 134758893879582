import React, { Component } from "react";
import { connect } from 'react-redux';
import { contentService } from '../_services';
import { AudiencePage } from '../AudiencePage';
import { PageLoader } from '../PageLoader';
import {ErrorPage} from "../ErrorPage";

import "../../css/schools/schools.css";
import "../../css/audience/audience.css";

class SchoolsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestFailed: false
        };
    }

    componentDidMount() {
        contentService.getPageContent('schools')
            .then(content => {
                this.setState({
                    content: content
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    errorMessage: error
                })
            })
    }

    render() {
        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage}/>
            )
        }
        if (!this.state.content) {
            return (
                <PageLoader />
            )
        }

        const schoolsContent = this.state.content;
        return (
            <div className="schools-content">
                <AudiencePage pageContent={schoolsContent} pageTitle={"For Schools"} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedSchoolsPage = connect(mapStateToProps)(SchoolsPage);
export { connectedSchoolsPage as SchoolsPage };