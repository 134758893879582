// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    margin-top: 40px;
    margin-bottom: 40px;
}

.login-title {
    margin-bottom: 10px !important;
}

.login-container label, .login-container button, .login-container a, .login-container input, .alert {
    font-size: 20px;
}

.password-input {
    margin-bottom: 0;
}

.forgot-password-link a {
    padding-left: 0;
    padding-top: 0;
}`, "",{"version":3,"sources":["webpack://./css/login/login.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".login-container {\n    margin-top: 40px;\n    margin-bottom: 40px;\n}\n\n.login-title {\n    margin-bottom: 10px !important;\n}\n\n.login-container label, .login-container button, .login-container a, .login-container input, .alert {\n    font-size: 20px;\n}\n\n.password-input {\n    margin-bottom: 0;\n}\n\n.forgot-password-link a {\n    padding-left: 0;\n    padding-top: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
