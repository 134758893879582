// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password-container {
    margin-top: 40px;
    margin-bottom: 40px;
}

.reset-password-title {
    margin-bottom: 10px !important;
}

.reset-password-container label, .reset-password-container button, .reset-password-container a, .reset-password-container input, .alert {
    font-size: 20px;
}

.back-to-login-button {
    padding-left: 0;
}

.reset-password-email {
    font-size: 20px;
    margin-bottom: 10px;
    color: #aaa;
    display: block;
}`, "",{"version":3,"sources":["webpack://./css/reset-password/reset-password.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,WAAW;IACX,cAAc;AAClB","sourcesContent":[".reset-password-container {\n    margin-top: 40px;\n    margin-bottom: 40px;\n}\n\n.reset-password-title {\n    margin-bottom: 10px !important;\n}\n\n.reset-password-container label, .reset-password-container button, .reset-password-container a, .reset-password-container input, .alert {\n    font-size: 20px;\n}\n\n.back-to-login-button {\n    padding-left: 0;\n}\n\n.reset-password-email {\n    font-size: 20px;\n    margin-bottom: 10px;\n    color: #aaa;\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
