import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { contentService } from '../_services';
import { PageLoader } from '../PageLoader';
import {ErrorPage} from "../ErrorPage";
import Slider from "react-slick";

import "../../css/home/home.css";

const STATIC_IMAGES_PATH = process.env.STATIC_IMAGES_PATH;

class HomeCard extends Component {
    render() {
        const imageFile = this.props.card.image ? this.props.card.image.filepath : "logo.png";
        return (
            <div className="col-md-4">
                <img className="card-image" src={STATIC_IMAGES_PATH + imageFile} />
                <span className="card-title">{this.props.card.title}</span>
                <span className="card-description" dangerouslySetInnerHTML={{__html: this.props.card.description}} />
                {this.props.card.displayLink === "True" &&
                    <Link to={this.props.card.linkUrl} className="btn btn-link card-link"><button className="btn btn-primary-dark">{this.props.card.linkText}</button></Link>
                }
            </div>
        )
    }
}

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestFailed: false
        };
    }

    componentDidMount() {
        contentService.getPageContent('home')
            .then(content => {
                this.setState({
                    content: content
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    errorMessage: error
                })
            })
    }

    render() {
        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage}/>
            )
        }
        if (!this.state.content) {
            return (
                <PageLoader />
            )
        }

        const homeContent = this.state.content[0];
        const carouselSettings = {
            dots: false,
            autoplay: true,
            fade: true,
            pauseOnHover: false,
            infinite: true,
            speed: 1000,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
        };

        // Split cards into rows
        let i,j,cardRows = [];
        for (i=0, j=homeContent.cards.length; i<j; i+=3) {
            cardRows.push(homeContent.cards.slice(i,i+3));
        }
        return (
            <div className="home-content">
                <div className="row">
                    <Slider {...carouselSettings}>
                        {homeContent.images.map(function(image, i){
                            return (
                                <div key={image.id}>
                                    <div className="banner-image" style={{backgroundImage: "url(" + STATIC_IMAGES_PATH + image.filepath +  ")"}}/>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
                <div className="home-description row">
                    <span>{homeContent.description}</span>
                </div>
                <div className="home-page-divider">
                    <div className="home-page-line line-left" />
                    <span className="home-page-divider-text">***</span>
                    <div className="home-page-line line-right" />
                </div>
                <div className="home-title">
                    <span>{homeContent.title}</span>
                </div>
                <div className="home-sub-title">
                    <span>{homeContent.subtitle}</span>
                </div>
                {cardRows.map(function(cardRow, i){
                    return <div className="row home-cards" key={i}>
                        {cardRow.map(function(card){
                            return (
                                <HomeCard card={card} key={card.id} />
                            );
                        })}
                    </div>
                })}
                <div className="row home-subsection-row allow-white-space">
                    <div className="col-md-12">
                        <img className="home-subsection-image" src={STATIC_IMAGES_PATH + homeContent.subsection_image.filepath} />
                    </div>
                </div>
                <div className="row home-subsection-row allow-white-space">
                    <div className="col-md-12">
                        <span className="home-subsection-title">{homeContent.subsection_title}</span>
                    </div>
                </div>
                <div className="row home-subsection-row">
                    <div className="col-md-12">
                        <span className="home-subsection-description" dangerouslySetInnerHTML={{__html: homeContent.subsection_description}} />
                    </div>
                </div>
                <div className="row home-subsection-row allow-white-space">
                    <div className="col-md-12">
                        <span className="home-subsection-subtitle">{homeContent.subsection_subtitle}</span>
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {};
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };