// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-error-container {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #ccc;
}

.page-error-icon {
    display: block;
    font-size: 150px;
    color: #eee;
    margin: auto;
    padding-top: 40px;
}

.home-button {
    display: block;
    margin: 40px auto;
}

.home-button i {
    font-size: 46px;
}`, "",{"version":3,"sources":["webpack://./css/error/error.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".page-error-container {\n    font-size: 24px;\n    font-weight: bold;\n    text-align: center;\n    color: #ccc;\n}\n\n.page-error-icon {\n    display: block;\n    font-size: 150px;\n    color: #eee;\n    margin: auto;\n    padding-top: 40px;\n}\n\n.home-button {\n    display: block;\n    margin: 40px auto;\n}\n\n.home-button i {\n    font-size: 46px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
