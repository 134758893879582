const BrowserType = {
  MAC_CHROME: "MAC_CHROME",
  MAC_FIREFOX: "MAC_FIREFOX",
  MAC_OPERA: "MAC_OPERA",
  MAC_SAFARI: "MAC_SAFARI",
  MAC_UNKNOWN: "MAC_UNKNOWN",
  WINDOWS_CHROME: "WINDOWS_CHROME",
  WINDOWS_EDGE: "WINDOWS_EDGE",
  WINDOWS_FIREFOX: "WINDOWS_FIREFOX",
  WINDOWS_OPERA: "WINDOWS_OPERA",
  WINDOWS_UNKNOWN: "WINDOWS_UNKNOWN",
  UNKNOWN: "UNKNOWN"
};

export default BrowserType;
