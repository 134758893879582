import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';

import "../../css/reset-password/reset-password.css";

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        const params = new URLSearchParams(this.props.location.search);

        this.state = {
            password: '',
            email: params.get('email'),
            token: params.get('token'),
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email, password, token } = this.state;
        const { dispatch } = this.props;
        if (email && password && token) {
            dispatch(userActions.resetPassword(email, password, token));
        }
    }

    render() {
        const { email, password, submitted } = this.state;
        return (
            <div className="container reset-password-container">
                <div className="col-md-6 col-md-offset-3">
                    <h2 className="reset-password-title">Reset your password</h2>
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                            <label htmlFor="email">Email</label>
                            <span className="reset-password-email">{email}</span>
                            <label htmlFor="password">Password</label>
                            <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                            {submitted && !password &&
                                <div className="help-block">Password is required</div>
                            }
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary-dark">Save Password</button>
                        </div>
                        <div className="form-group">
                            <Link to="/login" className="btn btn-link back-to-login-button">Back to Login</Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedResetPasswordPage = connect(mapStateToProps)(ResetPasswordPage);
export { connectedResetPasswordPage as ResetPasswordPage };