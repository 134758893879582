import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {userService} from "../_services";

export const PrivateRoute = ({ component: Component, updateUserAuthenticationState, ...rest }) => (
    <Route {...rest} render={props => (
        userService.getAuthenticatedUser()
            ? <Component {...props} updateUserAuthenticationState={updateUserAuthenticationState} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
);