// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-loader-container {
    text-align: center;
    font-size: 24px;
    color: #ccc;
    padding-top: 40px;
}`, "",{"version":3,"sources":["webpack://./css/page-loader/page-loader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".page-loader-container {\n    text-align: center;\n    font-size: 24px;\n    color: #ccc;\n    padding-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
