import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';

import "../../css/forgotten-password/forgotten-password.css";

class ForgottenPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email } = this.state;
        const { dispatch } = this.props;
        if (email) {
            dispatch(userActions.forgotPassword(email));
        }
    }

    render() {
        const { email, submitted } = this.state;
        return (
            <div className="container forgotten-password-container">
                <div className="col-md-6 col-md-offset-3">
                    <h2 className="forgotten-password-title">Reset your password</h2>
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                            <label htmlFor="email">Email</label>
                            <input type="email" className="form-control" name="email" value={email} onChange={this.handleChange} />
                            {submitted && !email &&
                                <div className="help-block">Email is required</div>
                            }
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary-dark">Send Email</button>
                        </div>
                        <div className="form-group">
                            <Link to="/login" className="btn btn-link back-to-login-button">Back to Login</Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedForgottenPasswordPage = connect(mapStateToProps)(ForgottenPasswordPage);
export { connectedForgottenPasswordPage as ForgottenPasswordPage };