// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-and-conditions-content {
    font-size: 20px;
}

.terms-and-conditions-tabs {
    margin-top: 20px;
}

.terms-and-conditions-content .section-header {
    font-size: 28px;
    font-family: "Futura PT Condensed Bold", sans-serif;
}

.terms-and-conditions-content .section-bold {
    font-family: "Futura PT Condensed Bold", sans-serif;
}`, "",{"version":3,"sources":["webpack://./css/terms-and-conditions/terms-and-conditions.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,mDAAmD;AACvD;;AAEA;IACI,mDAAmD;AACvD","sourcesContent":[".terms-and-conditions-content {\n    font-size: 20px;\n}\n\n.terms-and-conditions-tabs {\n    margin-top: 20px;\n}\n\n.terms-and-conditions-content .section-header {\n    font-size: 28px;\n    font-family: \"Futura PT Condensed Bold\", sans-serif;\n}\n\n.terms-and-conditions-content .section-bold {\n    font-family: \"Futura PT Condensed Bold\", sans-serif;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
