import React, { Component } from "react";
import { connect } from 'react-redux';
import { Tabs, Tab } from "react-bootstrap";

import "../../css/terms-and-conditions/terms-and-conditions.css";

class TermsAndConditionsPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container terms-and-conditions-content">
                <Tabs defaultActiveKey={this.props.defaultActiveKey} id="terms-and-conditions-tabs" className="terms-and-conditions-tabs">
                    <Tab eventKey={1} title="Terms And Conditions">
                        <span className="section-header">Terms and Conditions</span>
                        <br/>
                        Last updated: April, 2020
                        <br/><br/>
                        Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the www.becauseneatnessmatters.com website (the "Service") operated by Because Neatness Matters LLC ("us", "we", or "our").
                        <br/><br/>
                        Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the Service.
                        <br/><br/>
                        By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you do not have permission to access the Service.
                        <br/><br/>
                        <span className="section-header">Purchases</span>
                        <br/>
                        If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.
                        <br/><br/>
                        You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.
                        <br/><br/>
                        The service may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.
                        <br/><br/>
                        We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.
                        <br/><br/>
                        We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.
                        <br/><br/>
                        <span className="section-header">Availability, Errors and Inaccuracies</span>
                        <br/>
                        We are constantly updating product and service offerings on the Service. We may experience delays in updating information on the Service and in our advertising on other web sites. The information found on the Service may contain errors or inaccuracies and may not be complete or current. Products or services may be mispriced, described inaccurately, or unavailable on the Service and we cannot guarantee the accuracy or completeness of any information found on the Service.
                        <br/><br/>
                        We therefore reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.
                        <br/><br/>
                        <span className="section-header">Contests, Sweepstakes and Promotions</span>
                        <br/>
                        Any contests, sweepstakes or other promotions (collectively, "Promotions") made available through the Service may be governed by rules that are separate from these Terms Conditions. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms and Conditions, the Promotion rules will apply.
                        <br/><br/>
                        <span className="section-header">Subscriptions</span>
                        <br/>
                        Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set on an annual basis.
                        <br/><br/>
                        At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Because Neatness Matters LLC cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting Because Neatness Matters LLC customer support team.
                        <br/><br/>
                        A valid payment method, including credit card, is required to process the payment for your Subscription. You shall provide Because Neatness Matters LLC with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Because Neatness Matters LLC to charge all Subscription fees incurred through your account to any such payment instruments.
                        <br/><br/>
                        Should automatic billing fail to occur for any reason, Because Neatness Matters LLC will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
                        <br/><br/>
                        <span className="section-header">Fee Changes</span>
                        <br/>
                        Because Neatness Matters LLC, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.
                        <br/><br/>
                        Because Neatness Matters LLC will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.
                        <br/><br/>
                        Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.
                        <br/><br/>
                        <span className="section-header">Refunds</span>
                        <br/>
                        Except when required by law, paid Subscription fees are non-refundable.
                        <br/><br/>
                        <span className="section-header">Accounts</span>
                        <br/>
                        When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.
                        <br/><br/>
                        You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
                        <br/><br/>
                        You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.
                        <br/><br/>
                        We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.
                        <br/><br/>
                        <span className="section-header">Intellectual Property</span>
                        <br/>
                        The Service and its original content, features and functionality are and will remain the exclusive property of Because Neatness Matters LLC and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Because Neatness Matters LLC.
                        <br/><br/>
                        <span className="section-header">Links To Other Web Sites</span>
                        <br/>
                        Our Service may contain links to third party web sites or services that are not owned or controlled by Because Neatness Matters LLC
                        <br/><br/>
                        Because Neatness Matters LLC has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.
                        <br/><br/>
                        You acknowledge and agree that Because Neatness Matters LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third party web sites or services.
                        <br/><br/>
                        We strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services that you visit.
                        <br/><br/>
                        <span className="section-header">Termination</span>
                        <br/>
                        We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
                        <br/><br/>
                        If you wish to terminate your account, you may simply discontinue using the Service.
                        <br/><br/>
                        All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                        <br/><br/>
                        <span className="section-header">Indemnification</span>
                        <br/>
                        You agree to defend, indemnify and hold harmless Because Neatness Matters LLC and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password, or b) a breach of these Terms.
                        <br/><br/>
                        <span className="section-header">Limitation Of Liability</span>
                        <br/>
                        In no event shall Because Neatness Matters LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
                        <br/><br/>
                        <span className="section-header">Disclaimer</span>
                        <br/>
                        Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.
                        <br/><br/>
                        Because Neatness Matters LLC its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.
                        <br/><br/>
                        <span className="section-header">Exclusions</span>
                        <br/>
                        Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.
                        <br/><br/>
                        <span className="section-header">Governing Law</span>
                        <br/>
                        These Terms shall be governed and construed in accordance with the laws of Michigan, United States, without regard to its conflict of law provisions.
                        <br/><br/>
                        Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.
                        <br/><br/>
                        <span className="section-header">Changes</span>
                        <br/>
                        We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                        <br/><br/>
                        By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.
                        <br/><br/>
                        <span className="section-header">Contact Us</span>
                        <br/>
                        If you have any questions about these Terms, please contact us.
                        <br/><br/>
                        <span className="section-header">Terms of Sale</span>
                        <br/>
                        These terms and conditions of sale govern all sales of goods or services (collectively, “Goods”) from Because Neatness Matters LLC (“Seller”) to buyer (“Customer”).
                        <br/><br/>
                        <span className="section-header">Governing/Controlling Terms</span>
                        <br/>
                        The terms contained in this agreement will govern this transaction and, except as provided in this agreement, will supersede any purchase order submitted to Seller by Customer. All other terms contained in any prior communication between Customer and Seller, oral or written, including, without limitation, Customer’s purchase order, other than the identity of and quantity of the item(s) being purchased, are void and are rejected and will not be binding upon Seller. Customer will have assented to all terms contained in this agreement if all or any portion of the Goods described in this agreement are shipped or an invoice is presented in connection with said Goods.
                        <br/><br/>
                        <span className="section-header">Prices</span>
                        <br/>
                        Except for written quotations that specifically allow price protection for a certain period, all prices are subject to change by Seller without notice prior to placing of any order.
                        <br/><br/>
                        <span className="section-header">Payment Terms</span>
                        <br/>
                        All accounts are due based upon the terms stated on the invoice of Seller and the remittance address reflected on those invoices. As collateral security for the payment of the purchase price of the Goods, Customer grants to Seller a security interest in the right, title and interest of Customer in, to and under the Goods, wherever located, and whether now existing or hereafter arising or acquired occasionally, and in all accessions thereto and replacements or modifications thereof. The security interest granted under this provision constitutes a purchase money security interest under the Uniform Commercial Code. Customer agrees to assist Seller in taking any action Seller deems necessary or appropriate to perfect and protect Seller’s security interest, including filing any financing statements, continuation statements and other documents under the Uniform Commercial Code (or other similar laws) in effect in any jurisdiction regarding the security interest created hereby.
                        <br/><br/>
                        <span className="section-header">Late Payment</span>
                        <br/>
                        If Customer makes no payment to Seller when due, Customer’s account with Seller will become immediately due without notice or demand. Customer agrees that it will be charged one percent (1%) per month as a service charge plus up to the maximum interest permitted by law on any unpaid balance not paid by the date due, and Customer agrees to promptly pay said service charge and interest. Any portion of a month will be deemed a full month to calculate any service charge and interest. Waiver of any service charge or interest for any month by Seller will not be deemed a waiver of any future service charges or interest.
                        <br/><br/>
                        <span className="section-header">Taxes</span>
                        <br/>
                        Customer will pay, besides the prices quoted, all taxes, including, without limitation, sales, use and excise, imposed by any present or future law on the sale or use of the Goods covered thereby, unless Customer provides Seller with an exemption certificate acceptable to the applicable taxing authorities. Any taxes which Seller may have to pay or collect under any existing or future law regarding the sale, purchase, delivery, storage, processing, use, or consumption of the Goods sold will be for the account of Customer, who will promptly pay the amount thereof to Seller upon demand.
                        <br/><br/>
                        <span className="section-header">Claims</span>
                        <br/>
                        No claim for damages, defects, shortages, or otherwise will be valid unless received in writing by Seller within ten (10) days of delivery of Goods to Customer. Following such ten (10) day period, Customer will be deemed to have irrevocably accepted the Goods if not accepted by Customer prior thereto. If the Goods are damaged or defective and the manufacturer acknowledges responsibility under its warranty or otherwise, Seller may, but will not be required to (i) replace the damaged or defective Goods from inventory, if available, or (ii) allow Customer a credit for the purchase price of the Goods. Under no circumstances will Seller be liable for personal injury, death or property damage, or any other loss, damage, labor, cost of replacement or repair, or special, exemplary, consequential, incidental, indirect, punitive or liquidated damages, losses, or expenses (whether or not based upon negligence), including lost profits, lost income, lost revenues, business interruption or lost business, even if Seller has been advised of the possibility of such damages. Seller’s responsibility to Customer will not exceed the purchase price of the Goods purchased.
                        <br/><br/>
                        <span className="section-header">Special Orders</span>
                        <br/>
                        Orders for Goods that Seller rarely stocks may not be cancelled or returned (unless the manufacturer approves the return) for any reason after Seller has placed the order with the manufacturer. Special packing or handling requests by Customer will be at Customer’s expense.
                        <br/><br/>
                        <span className="section-header">Returned Goods</span>
                        <br/>
                        No Goods will be returned without Seller’s prior written permission and Customer’s proof of purchase. Returned Goods must be unused and in their original packaging. All returns are subject to freight, handling, and restocking charges. Special order and/or non/stock item returns are subject to manufacturers’ approval and credit for such returns is contingent upon that approval. Customer’s sole and exclusive remedy for Goods alleged to be defective will be limited to the manufacturer’s inspection and warranty, if any.
                        <br/><br/>
                        <span className="section-header">Credits; Set-off</span>
                        <br/>
                        Customer agrees that Seller will apply any credits issued to Customer against Customer’s outstanding invoices. If Customer has no outstanding invoices, Seller will issue a refund upon Customer’s written request. Customer may not set off any amounts or credits due Customer against any amount due Seller in this transaction.
                        <br/><br/>
                        <span className="section-header">Delivery; Back Orders</span>
                        <br/>
                        Seller will not be liable or responsible for any loss or damage due to any delays in delivery, or failure to deliver the Goods due to any circumstances beyond Seller’s control, including, but not limited to fire, flood, earthquake or other casualty, accidents, transportation delays, labor disputes, civil disorders, governmental orders or actions, acts of war or terrorism, or inability to secure Goods from Seller’s usual sources of supply. Seller will not be liable for any special, exemplary, consequential, incidental, indirect, punitive or liquidated damages, losses, or expenses (whether or not based upon negligence), including lost profits, lost income, lost revenues, business interruption or lost business, even if Seller has been advised of the possibility of such damages. Seller’s liability to Customer and/or any third party will not exceed the price paid by Customer or such party for the specific Goods or portion of the Goods causing the claim or cause of action. Back orders will be shipped as received unless Customer and Seller agree to different terms.
                        <br/><br/>
                        <span className="section-header">Risk of Loss</span>
                        <br/>
                        Unless otherwise agreed by the parties in writing, all Goods are shipped FCA point of shipment. Risk of loss will transfer to Customer upon tender of Goods to Customer or a common carrier. On shipments made directly to Customer from the manufacturer, the sale is complete and Seller’s responsibility to Customer ends upon delivery to the common carrier. Claims for Goods damaged or lost in transit must be made by Customer directly to the carrier and Seller will have no liability for such claims. Customer will assume all risk and liability for all loss, damage or injury to any person or property resulting from the installation and/or use of the Goods.
                        <br/><br/>
                        <span className="section-header">Warranty Disclaimer</span>
                        <br/>
                        Seller warrants it has title to the Goods sold. EXCEPT AS EXPRESSLY SET FORTH IN THIS PARAGRAPH, ALL GOODS AND/OR SERVICES PROVIDED BY SELLER AND ITS EMPLOYEES AND AGENTS ARE PROVIDED “AS IS,” “WHERE IS,” AND “WITH ALL FAULTS.” SELLER MAKES NO, AND HEREBY DISCLAIMS, WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WHETHER IMPLIED BY OPERATION OF LAW OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ALL WARRANTIES OF MERCHANTABILITY, SUITABILITY, NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE, OR OTHERWISE WITH RESPECT TO THE GOODS REFERRED TO HEREIN. CUSTOMER’S SOLE AND EXCLUSIVE WARRANTY, IF ANY, IS THAT PROVIDED BY ANY APPLICABLE THIRD PARTY MANUFACTURER, THE TERMS OF WHICH WILL BE FURNISHED UPON REQUEST.
                        <br/><br/>
                        <span className="section-header">Indemnification</span>
                        <br/>
                        Customer will defend, indemnify and hold harmless Seller and its owners, officers, directors, employees, agents and advisors against any claim, loss, damage, suit, cause of action, liability, judgment or expense (including, without limitation, attorneys’ fees and costs), resulting from relating to any injury, disease or death of persons or damage to or loss of any property, or violation of any laws or regulations resulting from or in connection with the sale, transport, or use of the Goods, whether based upon negligence, breach of warranty, strict liability in tort, contract, or any other theory of law. This paragraph will survive the termination of this transaction indefinitely.
                        <br/><br/>
                        <span className="section-header">Choice of Law</span>
                        <br/>
                        This agreement will be governed and controlled by the laws of the State of Michigan, including interpretation, enforceability, validity, and construction, without regard to any conflict of law provisions.
                        <br/><br/>
                        <span className="section-header">Venue</span>
                        <br/>
                        The parties submit to the exclusive jurisdiction and venue of the state or federal courts having jurisdiction over Wayne County, Michigan of any claims or actions arising, directly or indirectly, out of or related to this Agreement. The parties stipulate that the venues referenced in this Agreement are convenient.
                        <br/><br/>
                        <span className="section-header">Dispute Resolution/Venue</span>
                        <br/>
                        Any dispute arising out of this transaction will be resolved by litigation or binding arbitration (“Dispute Resolution”) at Seller’s sole option. Such Dispute Resolution will be conducted at a location selected by Seller and if binding arbitration occurs, by an arbitration service selected by Seller. A single arbitrator will preside over the arbitration, and the decision of the arbitrator will be final and binding upon the parties. If the parties cannot agree upon a single arbitrator, the procedure of the arbitration service selected by Seller will select an arbitrator. All actions arising out of or related to this transaction or the Goods sold, regardless of form or theory of liability, must be brought against Seller within the applicable statutory period, but in no event more than one (1) year after invoice.
                        <br/><br/>
                        <span className="section-header">Costs of Collection</span>
                        <br/>
                        If Seller retains or employs attorneys or other agencies to secure payment of any sums due from Customer, or otherwise enforce this document, Customer agrees to pay attorney and collection fees, costs, and any other related expenses besides all sums due.
                        <br/><br/>
                        <span className="section-header">Authority; Personal Liability</span>
                        <br/>
                        The person(s) executing this document on behalf of Customer represents he/she has authority to execute this document on behalf of Customer and acknowledges if no such authority exists that he/she by executing this document becomes personally liable under its terms.
                        <br/><br/>
                        <span className="section-header">Modification</span>
                        <br/>
                        The terms contained herein may not be amended, modified, supplemented, superseded, or otherwise altered except by a writing signed by an authorized representative of both Customer and Seller.
                        <br/><br/>
                        <span className="section-header">Non-waiver</span>
                        <br/>
                        Seller’s failure to insist upon strictly performing any term or condition herein will not be deemed a waiver of any of Seller’s rights or remedies, nor of its right to insist upon strictly performing the same or any other term herein. No waiver of any term or condition will be valid unless in writing and signed by an authorized representative of Seller.
                        <br/><br/>
                        <span className="section-header">Severability</span>
                        <br/>
                        If any term or provision is held to be illegal, invalid or unenforceable under any present or future laws, regulations, or ordinances of any federal, state, or other government to which this transaction is subject, such term or provision will be severable and the remaining terms and provisions herein will remain in full force and effect and will not be affected by such illegal, invalid or unenforceable term or provision
                        <br/><br/>
                        <span className="section-header">Force Majeure</span>
                        <br/>
                        Seller will be excused for the period of any delay in the performance of any obligations when prevented from so doing by causes beyond its control, including without limitation, acts of God (including fire, flood, earthquake, storm, hurricane or other natural disaster or casualty), civil commotion, government sanction, blockage, embargo, labor disputes, strike, lockout, inability to obtain any material or services, war (declared or undeclared) or acts of terrorism.
                        <br/><br/>
                        <span className="section-header">Entire Agreement</span>
                        <br/>
                        This document constitutes the entire, complete, and exclusive agreement between Customer and Seller regarding the subject matter hereof and contains all the agreements and conditions of sale. No course of dealing or usage of the trade will apply unless incorporated herein.
                        <br/><br/>
                    </Tab>
                    <Tab eventKey={2} title="Privacy Policy">
                        <span className="section-header">Privacy Policy</span>
                        <br/>
                        Effective date: August 27, 2018
                        <br/><br/>
                        Because Neatness Matters LLC ("us", "we", or "our") operates the www.becauseneatnessmatters.com website (hereinafter referred to as the "Service").
                        <br/><br/>
                        This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.
                        <br/><br/>
                        We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from www.becauseneatnessmatters.com
                        <br/><br/>
                        <span className="section-header">Definitions</span>
                        <br/>
                        <span className="section-bold">Service</span>
                        <br/>
                        Service is the www.becauseneatnessmatters.com website operated by Because Neatness Matters LLC
                        <br/><br/>
                        <span className="section-bold">Personal Data</span>
                        <br/>
                        Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
                        <br/><br/>
                        <span className="section-bold">Usage Data</span>
                        <br/>
                        Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                        <br/><br/>
                        <span className="section-bold">Cookies</span>
                        <br/>
                        Cookies are small files stored on your device (computer or mobile device).
                        <br/><br/>
                        <span className="section-bold">Data Controller</span>
                        <br/>
                        Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.
                        <br/>
                        For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.
                        <br/><br/>
                        <span className="section-bold">Data Processors (or Service Providers)</span>
                        <br/>
                        Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.
                        <br/>
                        We may use the services of various Service Providers in order to process your data more effectively.
                        <br/><br/>
                        <span className="section-bold">Data Subject (or User)</span>
                        <br/>
                        Data Subject is any living individual who is using our Service and is the subject of Personal Data.
                        <br/><br/>
                        <span className="section-header">Information Collection and Use</span>
                        <br/>
                        We collect several different types of information for various purposes to provide and improve our Service to you.
                        <br/><br/>
                        <span className="section-header">Types of Data Collected</span>
                        <br/>
                        <span className="section-bold">Personal Data</span>
                        <br/>
                        While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
                        <br/><br/>
                        Email address
                        <br/>
                        First name and last name
                        <br/>
                        Phone number
                        <br/>
                        Address, State, Province, ZIP/Postal code, City
                        <br/>
                        Cookies and Usage Data
                        <br/><br/>
                        We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or the instructions provided in any email we send.
                        <br/><br/>
                        <span className="section-bold">Usage Data</span>
                        <br/>
                        We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                        <br/><br/>
                        <span className="section-bold">Tracking Cookies Data</span>
                        <br/>
                        We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
                        <br/><br/>
                        Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.
                        <br/><br/>
                        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
                        <br/><br/>
                        Examples of Cookies we use:
                        <br/><br/>
                        <span className="section-bold">Session Cookies.</span> We use Session Cookies to operate our Service.
                        <br/>
                        <span className="section-bold">Preference Cookies.</span> We use Preference Cookies to remember your preferences and various settings.
                        <br/>
                        <span className="section-bold">Security Cookies.</span> We use Security Cookies for security purposes.
                        <br/><br/>
                        <span className="section-header">Use of Data</span>
                        <br/>
                        Because Neatness Matters LLC uses the collected data for various purposes:
                        <br/><br/>
                        To provide and maintain our Service
                        <br/>
                        To notify you about changes to our Service
                        <br/>
                        To allow you to participate in interactive features of our Service when you choose to do so
                        <br/>
                        To provide customer support
                        <br/>
                        To gather analysis or valuable information so that we can improve our Service
                        <br/>
                        To monitor the usage of our Service
                        <br/>
                        To detect, prevent and address technical issues
                        <br/>
                        To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or inquired about unless you have opted not to receive such information
                        <br/><br/>
                        <span className="section-header">Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)</span>
                        <br/>
                        If you are from the European Economic Area (EEA), Because Neatness Matters LLC legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.
                        <br/><br/>
                        Because Neatness Matters LLC may process your Personal Data because:
                        <br/><br/>
                        We need to perform a contract with you
                        <br/>
                        You have given us permission to do so
                        <br/>
                        The processing is in our legitimate interests and it is not overridden by your rights
                        <br/>
                        For payment processing purposes
                        <br/>
                        To comply with the law
                        <br/><br/>
                        <span className="section-header">Retention of Data</span>
                        <br/>
                        Because Neatness Matters LLC will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.
                        <br/><br/>
                        Because Neatness Matters LLC will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.
                        <br/><br/>
                        <span className="section-header">Transfer of Data</span>
                        <br/>
                        Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
                        <br/><br/>
                        If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United States and process it there.
                        <br/><br/>
                        Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                        <br/><br/>
                        Because Neatness Matters LLC will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
                        <br/><br/>
                        <span className="section-header">Disclosure of Data</span>
                        <br/>
                        <span className="section-bold">Business Transaction</span>
                        <br/>
                        If Because Neatness Matters LLC is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.
                        <br/><br/>
                        <span className="section-bold">Disclosure for Law Enforcement</span>
                        <br/>
                        Under certain circumstances, Because Neatness Matters LLC may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
                        <br/><br/>
                        <span className="section-bold">Legal Requirements</span>
                        <br/>
                        Because Neatness Matters LLC may disclose your Personal Data in the good faith belief that such action is necessary to:
                        <br/><br/>
                        To comply with a legal obligation
                        <br/>
                        To protect and defend the rights or property of Because Neatness Matters LLC
                        <br/>
                        To prevent or investigate possible wrongdoing in connection with the Service
                        <br/>
                        To protect the personal safety of users of the Service or the public
                        <br/>
                        To protect against legal liability
                        <br/><br/>
                        <span className="section-header">Security of Data</span>
                        <br/>
                        The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                        <br/><br/>
                        <span className="section-header">Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)</span>
                        <br/>
                        We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.
                        <br/><br/>
                        You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.
                        <br/><br/>
                        <span className="section-header">Your Data Protection Rights under the General Data Protection Regulation (GDPR)</span>
                        <br/>
                        If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Because Neatness Matters LLC aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.
                        <br/><br/>
                        If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.
                        <br/><br/>
                        In certain circumstances, you have the following data protection rights:
                        <br/><br/>
                        <span className="section-bold">The right to access, update or delete the information we have on you.</span> Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.
                        <br/>
                        <span className="section-bold">The right of rectification.</span> You have the right to have your information rectified if that information is inaccurate or incomplete.
                        <br/>
                        <span className="section-bold">The right to object.</span> You have the right to object to our processing of your Personal Data.
                        <br/>
                        <span className="section-bold">The right of restriction.</span> You have the right to request that we restrict the processing of your personal information.
                        <br/>
                        <span className="section-bold">The right to data portability.</span> You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.
                        <br/>
                        <span className="section-bold">The right to withdraw consent.</span> You also have the right to withdraw your consent at any time where Because Neatness Matters LLC relied on your consent to process your personal information.
                        <br/><br/>
                        Please note that we may ask you to verify your identity before responding to such requests.
                        <br/><br/>
                        You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).
                        <br/><br/>
                        <span className="section-header">Service Providers</span>
                        <br/>
                        We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services or assist us in analyzing how our Service is used.
                        <br/><br/>
                        These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                        <br/><br/>
                        <span className="section-bold">Analytics</span>
                        <br/>
                        We may use third-party Service Providers to monitor and analyze the use of our Service.
                        <br/><br/>
                        <span className="section-bold">Google Analytics</span>
                        <br/>
                        Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.
                        <br/><br/>
                        You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.
                        <br/><br/>
                        For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en
                        <br/><br/>
                        <span className="section-bold">Payments</span>
                        <br/>
                        We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).
                        <br/><br/>
                        We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
                        <br/><br/>
                        The payment processors we work with are:
                        <br/><br/>
                        <span className="section-bold">Stripe</span>
                        <br/>
                        Their Privacy Policy can be viewed at https://stripe.com/us/privacy
                        <br/><br/>
                        <span className="section-header">Links to Other Sites</span>
                        <br/>
                        Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
                        <br/><br/>
                        We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
                        <br/><br/>
                        <span className="section-header">Children's Privacy</span>
                        <br/>
                        Our Service does not address anyone under the age of 18 ("Children").
                        <br/><br/>
                        We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
                        <br/><br/>
                        <span className="section-header">Changes to This Privacy Policy</span>
                        <br/>
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                        <br/><br/>
                        We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
                        <br/><br/>
                        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                        <br/><br/>
                        <span className="section-header">Contact Us</span>
                        <br/>
                        If you have any questions about this Privacy Policy, please contact us:
                        <br/><br/>
                        By email: martha@becauseneatnessmatters.com
                        <br/>
                        By visiting this page on our website: www.becauseneatnessmatters.com
                        <br/><br/>
                    </Tab>
                    <Tab eventKey={3} title="Cookies Policy">
                        <span className="section-header">Cookies Policy</span>
                        <br/>
                        Last updated: August 23, 2018
                        <br/><br/>
                        Because Neatness Matters LLC ("us", "we", or "our") uses cookies on the www.becauseneatnessmatters.com website (the "Service"). By using the Service, you consent to the use of cookies.
                        <br/><br/>
                        Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.
                        <br/><br/>
                        <span className="section-header">What are cookies</span>
                        <br/>
                        Cookies are small pieces of text sent to your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you.
                        <br/><br/>
                        Cookies can be "persistent" or "session" cookies. Persistent cookies remain on your personal computer or mobile device when you go offline, while session cookies are deleted as soon as you close your web browser.
                        <br/><br/>
                        <span className="section-header">How Because Neatness Matters LLC uses cookies</span>
                        <br/>
                        When you use and access the Service, we may place a number of cookies files in your web browser.
                        <br/><br/>
                        We use cookies for the following purposes:
                        <br/><br/>
                        To enable certain functions of the Service
                        <br/>
                        To provide analytics
                        <br/>
                        To store your preferences
                        <br/><br/>
                        We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:
                        <br/><br/>
                        Essential cookies. We may use cookies to remember information that changes the way the Service behaves or looks, such as a user's language preference on the Service.
                        <br/><br/>
                        Accounts-related cookies. We may use accounts-related cookies to authenticate users and prevent fraudulent use of user accounts. We may use these cookies to remember information that changes the way the Service behaves or looks, such as the "remember me" functionality.
                        <br/><br/>
                        Analytics cookies. We may use analytics cookies to track information how the Service is used so that we can make improvements. We may also use analytics cookies to test new advertisements, pages, features or new functionality of the Service to see how our users react to them.
                        <br/><br/>
                        <span className="section-header">Third-party cookies</span>
                        <br/>
                        In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.
                        <br/><br/>
                        <span className="section-header">What are your choices regarding cookies</span>
                        <br/>
                        If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.
                        <br/><br/>
                        Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.
                        <br/><br/>
                        For the Chrome web browser, please visit this page from Google: https://support.google.com/accounts/answer/32050
                        <br/>
                        For the Internet Explorer web browser, please visit this page from Microsoft: http://support.microsoft.com/kb/278835
                        <br/>
                        For the Firefox web browser, please visit this page from Mozilla: https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                        <br/>
                        For the Safari web browser, please visit this page from Apple: https://support.apple.com/kb/PH21411?locale=en_US
                        <br/>
                        For any other web browser, please visit your web browser's official web pages.
                        <br/><br/>
                        <span className="section-header">Where can you find more information about cookies</span>
                        <br/>
                        You can learn more about cookies and the following third-party websites:
                        <br/><br/>
                        AllAboutCookies: http://www.allaboutcookies.org/
                        <br/>
                        Network Advertising Initiative: http://www.networkadvertising.org/
                        <br/><br/>
                    </Tab>
                    <Tab eventKey={4} title="Disclaimer">
                        <span className="section-header">Disclaimer</span>
                        <br/>
                        Last updated: August 23, 2018
                        <br/><br/>
                        The information contained on www.becauseneatnessmatters.com website (the "Service") is for general information purposes only.
                        <br/><br/>
                        Because Neatness Matters LLC assumes no responsibility for errors or omissions in the contents on the Service.
                        <br/><br/>
                        In no event shall Because Neatness Matters LLC be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. Because Neatness Matters LLC reserves the right to make additions, deletions, or modification to the contents on the Service at any time without prior notice.
                        <br/><br/>
                        Because Neatness Matters LLC does not warrant that the Service is free of viruses or other harmful components.
                        <br/><br/>
                        <span className="section-header">External links disclaimer</span>
                        <br/>
                        www.becauseneatnessmatters.com website may contain links to external websites that are not provided or maintained by or in any way affiliated with Because Neatness Matters LLC
                        <br/><br/>
                        Please note that the Because Neatness Matters LLC does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.
                        <br/><br/>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedTermsAndConditionsPage = connect(mapStateToProps)(TermsAndConditionsPage);
export { connectedTermsAndConditionsPage as TermsAndConditionsPage };