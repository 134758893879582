import React, {Component} from "react";
import { connect } from 'react-redux';
import {subscriptionService, userService} from "../_services";
import { ErrorPage } from "../ErrorPage";
import { PageLoader } from "../PageLoader";
import { Link } from "react-router-dom";
import {OverlayTrigger, Popover} from "react-bootstrap";

import "../../css/subscription-management/subscription-management.css";

const STATIC_IMAGES_PATH = process.env.STATIC_IMAGES_PATH;


class SubscriptionPageLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            processing: false,
            showErrorMessage: false,
        };
        this.executeSubscriptionFileAction = this.executeSubscriptionFileAction.bind(this);
        this.openSubscriptionFile = this.openSubscriptionFile.bind(this);
        this.saveSubscriptionFile = this.saveSubscriptionFile.bind(this);
    }

    executeSubscriptionFileAction(fileId, fileName, callback) {
        if (this.state.processing) {
            return
        }

        this.setState({
            processing: true,
            showErrorMessage: false
        })

        subscriptionService.getUserSubscriptionFile(this.props.userId, this.props.userSubscriptionId, fileId)
            .then(subscriptionFile => {
                callback(fileId, fileName, subscriptionFile)
                this.setState({ processing: false })
            }, (error) => {
                this.setState({
                    errorMessage: error,
                    processing: false,
                    showErrorMessage: true
                })
            });
    }

    openSubscriptionFile(fileId, fileName, subscriptionFile) {
        window.open(window.URL.createObjectURL(subscriptionFile))
    }

    saveSubscriptionFile(fileId, fileName, subscriptionFile) {
        let fileURL = window.URL.createObjectURL(subscriptionFile);
        let tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.setAttribute('download', fileName + ".pdf");
        tempLink.click();
        window.URL.revokeObjectURL(fileURL);
    }

    render() {
        const hideErrorMessage = this.state.showErrorMessage ? '' : 'hide-element';
        const fileProcessing = this.state.processing ? 'processing' : '';
        return (
            <div className="subscription-management-file-container">
                <button className={"btn-link subscription-management-file " + fileProcessing} onClick={() => {
                    this.executeSubscriptionFileAction(this.props.fileId, this.props.fileName, this.saveSubscriptionFile);
                }}><i className="fa fa-download subscription-management-file-action action-enabled"/></button>
                <button className={"btn-link subscription-management-file " + fileProcessing} onClick={() => {
                    this.executeSubscriptionFileAction(this.props.fileId, this.props.fileName, this.openSubscriptionFile);
                }}>{this.props.fileName}<i className="fa fa-spinner fa-spin file-loading-spinner"/></button>
                <div className={ 'error-box ' + hideErrorMessage }>
                    <i className="fas fa-exclamation-circle" />
                    <span>{this.state.errorMessage}</span>
                </div>
            </div>
        )
    }
}

class SubscriptionManagementPage extends Component {
    constructor(props) {
        super(props);
        this.props.updateUserAuthenticationState();

        this.state = {
            user: this.props.user,
            userSubscriptionId: props.match.params.userSubscriptionId,
            userSubscription: null,
            sharingEmail: "",
            submitted: false,
            sharingFormClass: "hide-element",
            subscriptionDetailsClass: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.toggleSharingOn = this.toggleSharingOn.bind(this);
        this.toggleSharingOff = this.toggleSharingOff.bind(this);
        this.undoSharing = this.undoSharing.bind(this);
        this.shareSubscription = this.shareSubscription.bind(this);
    }

    componentDidMount() {
        userService.getUserSubscription(this.state.user.id, this.state.userSubscriptionId)
            .then(userSubscription => {
                this.setState({
                    userSubscription: userSubscription,
                    requestCompleted: true
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    requestCompleted: true,
                    errorMessage: error.message,
                    errorIconClass: error.iconClasses
                })
            });
    }

    toggleSharingOn() {
		this.setState({
                sharingFormClass: "",
                subscriptionDetailsClass: "hide-element"
            }
        )
	}

	toggleSharingOff() {
		this.setState({
                sharingFormClass: "hide-element",
                subscriptionDetailsClass: ""
            }
        )
	}

	undoSharing() {
		// Send request to backend to remove shared_with PUT /users/user_id/subscriptions/user_subscription_id
        userService.unshareUserSubscription(this.state.user.id, this.state.userSubscriptionId)
            .then(userSubscription => {
                this.setState({
                    userSubscription: userSubscription,
                    requestCompleted: true
                });
                this.toggleSharingOff()
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    requestCompleted: true,
                    errorMessage: error.message,
                    errorIconClass: error.iconClasses
                })
            });
	}

	shareSubscription() {
        this.setState({
                submitted: true,
            }
        );
        const { sharingEmail } = this.state;
        if (sharingEmail && this.validateEmail(sharingEmail)) {
            userService.shareUserSubscription(this.state.user.id, this.state.userSubscriptionId, sharingEmail)
                .then(userSubscription => {
                    this.setState({
                        userSubscription: userSubscription,
                        requestCompleted: true
                    });
                    this.toggleSharingOff()
                }, (error) => {
                    this.setState({
                        requestFailed: true,
                        requestCompleted: true,
                        errorMessage: error.message,
                        errorIconClass: error.iconClasses
                    })
                });
        }
	}

	handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    render() {
        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage} iconClasses={this.state.errorIconClass}/>
            )
        }
        if (!this.state.userSubscription) {
            return (
                <PageLoader />
            )
        }

        const {submitted, sharingEmail} = this.state;
        let validEmail = this.validateEmail(sharingEmail);

        const imageFile = this.state.userSubscription.subscription.images.length > 0 ? this.state.userSubscription.subscription.images[0].filepath : "logo.png";
        let userId = this.state.user.id;
        let userSubscriptionId = this.state.userSubscription.id;
        let owner = this.state.userSubscription.owner === this.state.user.username ? "Me" : this.state.userSubscription.owner;
        let sharedWith = this.state.userSubscription.shared_with ? this.state.userSubscription.shared_with : "-";
        sharedWith = sharedWith === this.state.user.username ? "Me" : sharedWith;

        let showManagementCommands = this.state.userSubscription.owner === this.state.user.username ? "" : "hide-element";
        let showShareButton = sharedWith === "-" ? "" : "hide-element";
        let showUndoButton = sharedWith !== "-" ? "" : "hide-element";
        let clickableFilesClass = sharedWith === "-" || sharedWith === "Me" ? "" : "hide-element";
        let staticFilesClass = sharedWith === "-" || sharedWith === "Me" ? "hide-element" : "";

        const sharedFilePopover = (
            <Popover id="included-files-popover" title="Shared Files">
                You've shared these files with another user. You'll need to unshare them to regain access.
            </Popover>
        );


        return (
            <div className="container">
                <div className="subscription-management-breadcrumbs row">
                    <div className="col-md-12">
                        <Link to="/subscriptions" className="">Subscriptions</Link>
                        <span> / <Link to="/subscriptions/products" className="">Products</Link></span>
                        <span> / {this.state.userSubscription.subscription.title}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <img className="subscription-management-image" src={STATIC_IMAGES_PATH + imageFile} />
                    </div>
                    <div className="col-md-9">
                        <div className="subscriptions-container">
                            <span className="subscription-management-title">{this.state.userSubscription.subscription.title}</span>
                            <div className={"row " + this.state.subscriptionDetailsClass}>
                                <div className="col-md-12">
                                    <div className="subscription-management-info-block">
                                        <span className="subscription-management-info-label">Owner: </span>
                                        <span className="subscription-management-info">{owner}</span>
                                    </div>
                                    <div className="subscription-management-info-block">
                                        <span className="subscription-management-info-label">Shared With: </span>
                                        <span className="subscription-management-info">{sharedWith}</span>
                                        <div className={"sharing-commands-container " + showManagementCommands}>
                                            <button className={"btn btn-primary-dark share-button " + showShareButton} onClick={() => { this.toggleSharingOn() }}>
                                                Share <i className="fas fa-share-square"/>
                                            </button>
                                            <button className={"btn btn-primary-dark undo-share-button " + showUndoButton} onClick={() => { this.undoSharing() }}>
                                                Undo <i className="fas fa-undo"/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="subscription-management-info-block">
                                        <span className="subscription-management-info-label">Expires: </span>
                                        <span className="subscription-management-info">{this.state.userSubscription.expires}</span>
                                    </div>
                                    {this.state.userSubscription.subscription.pdf_files.length > 0 &&
                                        <div className={"subscription-management-info-block " + clickableFilesClass}>
                                            <span className="subscription-management-info-label">Included Files</span>
                                            {this.state.userSubscription.subscription.pdf_files.map(function (pdf, i) {
                                                return (
                                                    <SubscriptionPageLink key={pdf.id} userId={userId} userSubscriptionId={userSubscriptionId} fileId={pdf.id} fileName={pdf.name} />
                                                );
                                            })}
                                        </div>
                                    }
                                    {this.state.userSubscription.subscription.pdf_files.length > 0 &&
                                        <div className={"subscription-management-info-block " + staticFilesClass}>
                                            <span className="subscription-management-info-label">Shared Files</span>
                                            <OverlayTrigger trigger="click" rootClose={true} placement="right" overlay={sharedFilePopover}>
                                                <i className="fas fa-info-circle shared-files-info-icon"/>
                                            </OverlayTrigger>
                                            {this.state.userSubscription.subscription.pdf_files.map(function(pdf, i){
                                                return (
                                                    <div key={pdf.id} className="subscription-management-file-container">
                                                        <span className="subscription-management-file">{pdf.name}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={"row " + this.state.sharingFormClass}>
                                <div className="col-md-12">
                                    <div className="subscription-sharing-form">
                                        <div className={'form-group' + (submitted && !sharingEmail ? ' has-error' : '') + (submitted && !validEmail ? ' has-error' : '')} >
                                            <label htmlFor="sharingEmail">Email</label>
                                            <input type="email" className="form-control" name="sharingEmail" value={sharingEmail} onChange={this.handleChange} />
                                            {submitted && !sharingEmail &&
                                                <div className="help-block">Email is required</div>
                                            }
                                            {submitted && !validEmail &&
                                                <div className="help-block">Email format is invalid</div>
                                            }
                                        </div>
                                        <button className="btn btn-primary-dark share-submit-button" onClick={() => { this.shareSubscription() }}>
                                            Submit
                                        </button>
                                        <a className="share-cancel-button" onClick={this.toggleSharingOff}>Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedSubscriptionManagementPage = connect(mapStateToProps)(SubscriptionManagementPage);
export { connectedSubscriptionManagementPage as SubscriptionManagementPage };