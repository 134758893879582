// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonial-slider-container {
    color: #fff;
    font-size: 22px;
    margin-top: 50px;
    margin-bottom: 30px;
    font-family: "FF Uberhand Pro Medium", sans-serif;
}

.testimonial-quote {
    text-align: center;
}

.testimonial-quote > :first-child:before, .testimonial-quote > :first-child:after {
    content: "\\"";
}

.testimonial-slider-author {
    margin-top: 30px;
}

.testimonial-slider-author, .testimonial-slider-occupation {
    text-align: right;
    display: block;
}

.testimonial-slider .slick-track {
  display: flex;
}
.testimonial-slider .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./css/testimonials/slider.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,iDAAiD;AACrD;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".testimonial-slider-container {\n    color: #fff;\n    font-size: 22px;\n    margin-top: 50px;\n    margin-bottom: 30px;\n    font-family: \"FF Uberhand Pro Medium\", sans-serif;\n}\n\n.testimonial-quote {\n    text-align: center;\n}\n\n.testimonial-quote > :first-child:before, .testimonial-quote > :first-child:after {\n    content: \"\\\"\";\n}\n\n.testimonial-slider-author {\n    margin-top: 30px;\n}\n\n.testimonial-slider-author, .testimonial-slider-occupation {\n    text-align: right;\n    display: block;\n}\n\n.testimonial-slider .slick-track {\n  display: flex;\n}\n.testimonial-slider .slick-track .slick-slide {\n  display: flex;\n  height: auto;\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
