import React, { Component } from "react";
import { connect } from 'react-redux';
import { contentService } from '../_services';
import { PageLoader } from '../PageLoader';
import {ErrorPage} from "../ErrorPage";
import ReactPlayer from 'react-player'

import "../../css/video-subscriptions/video-subscriptions.css";
import {Link} from "react-router-dom";

const STATIC_IMAGES_PATH = process.env.STATIC_IMAGES_PATH;
const STATIC_VIDEOS_PATH = process.env.STATIC_VIDEOS_PATH;

class VideoBlock extends Component {
    render() {
        const imageFile = this.props.video.thumbnail ? this.props.video.thumbnail.filepath : "logo.png";
        const selectedVideoClass = this.props.selectedVideoId === this.props.video.id ? "selected-video" : "";
        return (
            <div className={"row video-row " + selectedVideoClass} onClick={() => { this.props.setVideoFile(this.props.video) }}>
                <div className="col-md-4">
                    <img className="video-thumbnail" src={STATIC_IMAGES_PATH + imageFile} />
                </div>
                <div className="col-md-8">
                    <span className="video-title">{this.props.video.title}</span>
                </div>
            </div>
        )
    }
}

class VideoSubscriptionsPage extends Component {
    constructor(props) {
        super(props);
        this.props.updateUserAuthenticationState();

        this.state = {
            requestFailed: false,
            videoFilePath: "",
            selectedVideoId: 0
        };
        this.setVideoFile = this.setVideoFile.bind(this);
    }

    componentDidMount() {
        contentService.getPageContent('videos')
            .then(videos => {
                if (videos.length > 0) {
                    this.setState({
                        videoFilePath: videos[0].filepath,
                        selectedVideoId: videos[0].id
                    })
                }
                this.setState({
                    content: videos
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    errorMessage: error
                })
            })
    }

    setVideoFile(video) {
        this.setState({
            selectedVideoId: video.id,
            videoFilePath: video.filepath
        });
    }

    render() {
        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage}/>
            )
        }
        if (!this.state.content) {
            return (
                <PageLoader />
            )
        }

        const videos = this.state.content;
        const setVideoFileFunc = this.setVideoFile;
        let selectedVideoId = this.state.selectedVideoId;
        return (
            <div className="videos-content container">
                <div className="video-subscriptions-breadcrumbs row">
                    <div className="col-md-12">
                        <Link to="/subscriptions" className="">Subscriptions</Link>
                        <span> / Videos</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 col-md-push-4 video-player">
                        <ReactPlayer url={STATIC_VIDEOS_PATH + this.state.videoFilePath} controls={true} width="100%" height="100%" />
                    </div>
                    <div className="col-md-4 col-md-pull-8">
                        <div className="row video-list">
                            {videos.map(function(video, i){
                                return (
                                    <VideoBlock video={video} setVideoFile={setVideoFileFunc} selectedVideoId={selectedVideoId} key={video.id} />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {};
}

const connectedVideoSubscriptionsPage = connect(mapStateToProps)(VideoSubscriptionsPage);
export { connectedVideoSubscriptionsPage as VideoSubscriptionsPage };