import React, { Component } from "react";
import { connect } from 'react-redux';

import "../../css/contact/contact.css";
import {contactService} from "../_services";

const STATIC_IMAGES_PATH = process.env.STATIC_IMAGES_PATH;

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            occupation: '',
            occupationTwo: '',
            message: '',
            submitted: false,
            submittedSuccess: false,
            submittedError: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });

        let { name, email, phone, occupation, occupationTwo, message } = this.state;
        if (name && email && occupation && message) {
            if (occupation === "Other" && occupationTwo !== "") {
                occupation = occupationTwo
            }
            contactService.sendContactRequest(name, email, phone, occupation, message).then(
                response => {
                    this.setState({ submittedSuccess: true });
                },
                error => {
                    this.setState({ submittedError: true });
                }
            )
        }
    }

    render() {
        const { submitted, name, email, phone, occupation, occupationTwo, message } = this.state;
        let showContactSuccessClass = this.state.submittedSuccess ? '' : 'hide';
        let showContactErrorClass = this.state.submittedError ? '' : 'hide';
        let showContactFormClass = this.state.submittedSuccess || this.state.submittedError ? 'hide' : '';
        let showOccupationTwo = this.state.occupation !== "Other" ? 'hide' : '';

        return (
            <div className="contact-content">
                <div className="row contact-banner-image-container">
                    <div className="col-md-12">
                        <img className="contact-banner-image" src={STATIC_IMAGES_PATH + "contact-banner.png"}/>
                    </div>
                </div>
                <div className={"contact-form-status-container " + showContactSuccessClass}>
                    <i className="far fa-envelope contact-form-status-icon"/>
                    <span className="contact-form-status-message">Your message was sent successfully.</span>
                </div>
                <div className={"contact-form-status-container " + showContactErrorClass}>
                    <i className="far fa-frown contact-form-status-icon"/>
                    <span className="contact-form-status-message">We were unable to submit your message.</span>
                    <span className="contact-form-status-message">Please email us directly at contact@becauseneatnessmatters.com</span>
                </div>
                <div className={"contact-form well " + showContactFormClass}>
                    <span className="contact-title">Contact Us</span>
                    <span className="contact-email">contact@becauseneatnessmatters.com</span>
                    <span className="contact-phone">248-767-6005</span>
                    <form onSubmit={this.handleSubmit}>
                        <div className={'form-group' + (submitted && !name ? ' has-error' : '')}>
                            <label className="contact-field-label" htmlFor="name">Name*</label>
                            <input type="text" className="form-control contact-text-field" placeholder="Enter Name"
                                   name="name" value={name} onChange={this.handleChange}/>
                        </div>

                        <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                            <label className="contact-field-label" htmlFor="email">Email*</label>
                            <input type="email" className="form-control contact-text-field" placeholder="Enter Email"
                                   name="email" value={email} onChange={this.handleChange}/>
                        </div>

                        <div className={'form-group'}>
                            <label className="contact-field-label" htmlFor="phone">Phone</label>
                            <input type="text" className="form-control contact-text-field"
                                   placeholder="Enter Phone Number" name="phone" value={phone}
                                   onChange={this.handleChange}/>
                        </div>

                        <div className={'form-group' + (submitted && !occupation ? ' has-error' : '')}>
                            <label className="contact-field-label" htmlFor="occupation">Position/Title*</label>
                            <select className="form-control contact-text-field" name="occupation" value={occupation}
                                    onChange={this.handleChange}>
                                <option value="">----</option>
                                <option value="Home Educator">Home Educator</option>
                                <option value="Principal">Principal</option>
                                <option value="Teacher">Teacher</option>
                                <option value="Therapist">Therapist</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                        <div className={'form-group ' + showOccupationTwo}>
                            <label className="contact-field-label" htmlFor="occupationTwo">Position/Title</label>
                            <input type="text" className="form-control contact-text-field"
                                   placeholder="Enter Professional Title" name="occupationTwo" value={occupationTwo}
                                   onChange={this.handleChange}/>
                        </div>

                        <div className={'form-group' + (submitted && !message ? ' has-error' : '')}>
                            <label className="contact-field-label" htmlFor="name">Message*</label>
                            <textarea rows="6" className="form-control contact-text-area"
                                      placeholder="Enter Your Message" name="message" value={message}
                                      onChange={this.handleChange}/>
                        </div>

                        <button className="btn btn-primary-dark submit-button">Submit</button>
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedContactPage = connect(mapStateToProps)(ContactPage);
export {connectedContactPage as ContactPage};