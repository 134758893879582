import React, { Component } from "react";
import { connect } from 'react-redux';

import "../../css/product-subscriptions/product-subscriptions.css";
import {ErrorPage} from "../ErrorPage";
import {Link} from "react-router-dom";
import {userService} from "../_services";

class ProductSubscriptionRow extends Component {
    render() {
        let owner = this.props.userSubscription.owner === this.props.user.username ? "Me" : this.props.userSubscription.owner;
        let sharedWith = this.props.userSubscription.shared_with ? this.props.userSubscription.shared_with : "-";
        sharedWith = sharedWith === this.props.user.username ? "Me" : sharedWith;

        return (
            <div className={"row product-subscriptions-row " + this.props.classes} >
                <div className="col-md-4">
                    <Link to={"/users/" + this.props.user.id + "/subscriptions/" + this.props.userSubscription.id}>
                        {this.props.userSubscription.subscription.title}
                    </Link>
                </div>
                <div className="col-md-3">{owner}</div>
                <div className="col-md-3">{sharedWith}</div>
                <div className="col-md-2">{this.props.userSubscription.expires}</div>
            </div>
        )
    }
}

class ProductSubscriptionsPage extends Component {
    constructor(props) {
        super(props);
        this.props.updateUserAuthenticationState();

        this.state = {
            user: this.props.user,
            userSubscriptions: [],
            requestCompleted: false
        };
    }

    componentDidMount() {
        userService.getUserSubscriptions(this.state.user.id)
            .then(userSubscriptions => {
                this.setState({
                    userSubscriptions: userSubscriptions,
                    requestCompleted: true
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    requestCompleted: true,
                    errorMessage: error
                })
            })
    }

    render() {
        const { user } = this.state;
        let loadingSubscriptionsClass = this.state.requestCompleted  ? 'hide' : '';
        let emptySubscriptionsClass = !this.state.requestCompleted || this.state.userSubscriptions.length > 0 ? 'hide' : '';

        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage}/>
            )
        }

        return (
            <div className="product-subscriptions-content container">
                <div className="product-subscriptions-breadcrumbs row">
                    <div className="col-md-12">
                        <Link to="/subscriptions" className="">Subscriptions</Link>
                        <span> / Products</span>
                    </div>
                </div>
                <div className="product-subscriptions-list row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4 product-subscriptions-header">Title</div>
                            <div className="col-md-3 product-subscriptions-header">Owner</div>
                            <div className="col-md-3 product-subscriptions-header">Shared With</div>
                            <div className="col-md-2 product-subscriptions-header">Expires</div>
                        </div>
                        <div className={"empty-subscriptions-row row " + emptySubscriptionsClass}>
                            <span className="empty-subscriptions-message">No subscriptions to display.</span>
                        </div>
                        <div className={"loading-subscriptions-row row " + loadingSubscriptionsClass}>
                            <span className="loading-subscriptions-message">Loading Subscriptions ...</span>
                        </div>
                        {this.state.userSubscriptions.map(function(userSubscription, i){
                            let classes = "";
                            if (i % 2 === 1) {
                                classes = "bnm-light-gray"
                            }
                            return (
                                <ProductSubscriptionRow user={user} userSubscription={userSubscription} key={userSubscription.id} classes={classes} />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}
const connectedProductSubscriptionsPage = connect(mapStateToProps)(ProductSubscriptionsPage);
export { connectedProductSubscriptionsPage as ProductSubscriptionsPage };