// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.config-panel {
  background-color: #fefefe;
  border-radius: 10px;
  box-shadow: 0 1px 10px grey;
  padding: 12px 24px 24px 24px;
  display: block;
  width: 170px;
}

.config-section-title {
  font-family: 'BNM', sans-serif;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 6px;
  padding-top: 12px;
}

.config-radio-option {
  cursor: pointer;
  font-family: 'BNM', sans-serif;
  font-size: 16px;
  padding-bottom: 2px;
}
`, "",{"version":3,"sources":["webpack://./css/worksheet-maker/ConfigPanel.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,2BAA2B;EAC3B,4BAA4B;EAC5B,cAAc;EACd,YAAY;AACd;;AAEA;EACE,8BAA8B;EAC9B,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,8BAA8B;EAC9B,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".config-panel {\n  background-color: #fefefe;\n  border-radius: 10px;\n  box-shadow: 0 1px 10px grey;\n  padding: 12px 24px 24px 24px;\n  display: block;\n  width: 170px;\n}\n\n.config-section-title {\n  font-family: 'BNM', sans-serif;\n  font-size: 18px;\n  font-weight: bold;\n  padding-bottom: 6px;\n  padding-top: 12px;\n}\n\n.config-radio-option {\n  cursor: pointer;\n  font-family: 'BNM', sans-serif;\n  font-size: 16px;\n  padding-bottom: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
