import { authHeader } from '../_helpers';
import { Cookies } from 'react-cookie';

const API_BASE = process.env.API_URL;

export const userService = {
    login,
    logout,
    register,
    update,
    isUserAuthenticated,
    getAuthenticatedUser,
    getUserOrders,
    getUserSubscriptions,
    getUserSubscription,
    getUserWorksheetMakerSubscriptions,
    shareUserSubscription,
    unshareUserSubscription,
    sendForgotPasswordEmail,
    resetPassword
};

const cookies = new Cookies();
const csrftoken = cookies.get('csrftoken');

const userStorageKey = 'bnm-user';
let inMemoryCache = {};
let itemStore = localStorage;

if (!localStorage) {
  console.warn("Local Storage is not supported, data will not be persisted");

  itemStore = {};
  itemStore.getItem = function(key) {
    return inMemoryCache[key] ? inMemoryCache[key] : null;
  };
  itemStore.setItem = function(key, value) {
    inMemoryCache[key] = value;
  };
  itemStore.removeItem = function(key) {
    delete inMemoryCache[key];
  };
}

function isUserAuthenticated() {
    return !!itemStore.getItem(userStorageKey);
}

function getAuthenticatedUser() {
    return itemStore.getItem(userStorageKey);
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ username, password })
    };

    return fetch(API_BASE + '/users/authenticate', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject('Username or Password is incorrect.');
            }

            return response.json();
        })
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                itemStore.setItem(userStorageKey, JSON.stringify(user));
            }

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    itemStore.removeItem(userStorageKey);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken
        },
        body: JSON.stringify(user)
    };

    return fetch(API_BASE + '/users/register', requestOptions).then(response => {
        if (!response.ok) {
            return response.json().then(json => {
                let message = json.message ? json.message : 'Sorry, we were unable to register you at this time.';
                return Promise.reject(message);
            });
        }

        return response.json();
    });
}

function update(userData) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    };

    return fetch(API_BASE + '/users/' + userData.id, requestOptions)
        .then(response => {
            if (!response.ok) {
                return response.json().then(json => {
                    let message = json.message ? json.message : 'Username or Password is incorrect.';
                    return Promise.reject(message);
                });
            }

            return response.json();
        })
        .then(user => {
            if (user && user.token) {
                itemStore.setItem(userStorageKey, JSON.stringify(user));
            }

            return user;
        });
}

function getUserOrders(user_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(API_BASE + '/users/' + user_id + '/orders', requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403) {
                    return Promise.reject({
                        "message": "You don't have permission to access this data.",
                        "iconClasses": "fa fa-ban",
                    });
                }
                return Promise.reject({
                    "message": response.statusText
                });
            }

            return response.json();
        });
}

function _executeAuthorizedFetch(url) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403) {
                    return Promise.reject({
                        "message": "You don't have permission to access this data.",
                        "iconClasses": "fa fa-ban",
                    });
                }
                return Promise.reject({
                    "message": response.statusText
                });
            }

            return response.json();
        });
}


function getUserSubscriptions(user_id) {
    let url = API_BASE + '/users/' + user_id + '/subscriptions';
    return _executeAuthorizedFetch(url);
}

function getUserWorksheetMakerSubscriptions(user_id) {
    let url = API_BASE + '/users/' + user_id + '/worksheet-maker-subscriptions';
    return _executeAuthorizedFetch(url);
}

function getUserSubscription(userId, userSubscriptionId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(API_BASE + '/users/' + userId + '/subscriptions/' + userSubscriptionId, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403) {
                    return Promise.reject({
                        "message": "You don't have permission to access this data.",
                        "iconClasses": "fa fa-ban",
                    });
                }
                return Promise.reject({
                    "message": "Uh oh! Looks like we're having trouble loading this page."
                });
            }

            return response.json();
        });
}

function shareUserSubscription(userId, userSubscriptionId, shared_with) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ shared_with })
    };
    return fetch(API_BASE + '/users/' + userId + '/subscriptions/' + userSubscriptionId, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403) {
                    return Promise.reject({
                        "message": "You don't have permission to access this data.",
                        "iconClasses": "fa fa-ban",
                    });
                }
                return Promise.reject({
                    "message": "Uh oh! Looks like we're having trouble loading this page."
                });
            }

            return response.json();
        });
}

function unshareUserSubscription(userId, userSubscriptionId) {
    const clear_shared_with = true;
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ clear_shared_with })
    };
    return fetch(API_BASE + '/users/' + userId + '/subscriptions/' + userSubscriptionId, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403) {
                    return Promise.reject({
                        "message": "You don't have permission to access this data.",
                        "iconClasses": "fa fa-ban",
                    });
                }
                return Promise.reject({
                    "message": "Uh oh! Looks like we're having trouble loading this page."
                });
            }

            return response.json();
        });
}


function sendForgotPasswordEmail(email) {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ email })
    };

    return fetch(API_BASE + '/users/forgot-password', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject('Sorry, we were unable to send the password reset email.');
            }

            return response.json();
        });
}

function resetPassword(email, password, token) {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ email, password, token })
    };

    return fetch(API_BASE + '/users/reset-password', requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject('Unable to reset password. Your token may have expired, please request another password reset email.');
            }

            return response.json();
        });
}