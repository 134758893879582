import React from 'react';

class ConfigPanel extends React.Component {
  render() {
    return (
      <div className={'config-panel'}>
        {this.props.sections.map(this.renderSection.bind(this))}
      </div>
    );
  }

  renderSection(section) {
    return (
      <ConfigSection
        key={section.id}
        title={section.title}
        options={section.options}
        selectedOptionId={this.props.selectedOptionIds[section.id]}
        handleOptionClick={optionId =>
          this.props.handleOptionClick(section.id, optionId)
        }
      />
    );
  }
}

class ConfigSection extends React.Component {
  render() {
    return (
      <div>
        <div className="config-section-title">{this.props.title}</div>
        <ConfigRadioGroup
          options={this.props.options}
          selectedOptionId={this.props.selectedOptionId}
          handleOptionClick={this.props.handleOptionClick}
        />
      </div>
    );
  }
}

class ConfigRadioGroup extends React.Component {
  render() {
    return <form>{this.props.options.map(this.renderOption.bind(this))}</form>;
  }

  renderOption(option) {
    return (
      <ConfigRadioOption
        key={option.id}
        id={option.id}
        label={option.label}
        checked={this.props.selectedOptionId === option.id}
        handleOptionClick={this.props.handleOptionClick}
      />
    );
  }
}

class ConfigRadioOption extends React.Component {
  render() {
    return (
      <div className="config-radio-option">
        <input
          type="radio"
          value={this.props.id}
          checked={this.props.checked}
          onChange={() => this.props.handleOptionClick(this.props.id)}
        />
        {' ' + this.props.label} <br />
      </div>
    );
  }
}

export default ConfigPanel;
