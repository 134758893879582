import React, { Component } from "react";
import { connect } from 'react-redux';
import { contentService } from '../_services';
import { PageLoader } from '../PageLoader';
import {ErrorPage} from "../ErrorPage";

import "../../css/about/about.css";

const STATIC_IMAGES_PATH = process.env.STATIC_IMAGES_PATH;

class FAQBlock extends Component {
    render() {
        return (
            <div className="col-md-12">
                <span className="faq-question">{this.props.faq.question}</span>
                <span className="faq-answer" dangerouslySetInnerHTML={{__html: this.props.faq.answer}} />
            </div>
        )
    }
}

class AboutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestFailed: false
        };
    }

    componentDidMount() {
        contentService.getPageContent('about')
            .then(content => {
                this.setState({
                    content: content
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    errorMessage: error
                })
            })
    }

    render() {
        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage}/>
            )
        }
        if (!this.state.content) {
            return (
                <PageLoader />
            )
        }

        const aboutContent = this.state.content[0];
        return (
            <div className="about-content container">
                <div className="about-biography-row row">
                    <div className="col-md-4">
                        <img className="about-image" src={STATIC_IMAGES_PATH + "biography.jpg"} />
                    </div>
                    <div className="col-md-8">
                        <span className="about-page-title">{aboutContent.title}</span>
                        <span className="about-page-biography" dangerouslySetInnerHTML={{__html: aboutContent.biography}} />
                    </div>
                </div>
                <div className="row faq-row">
                    <span className="faq-section-title">FAQ's</span>
                    {aboutContent.faqs.map(function(faq, i){
                        return (
                            <FAQBlock faq={faq} key={faq.id} />
                        );
                    })}
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {};
}

const connectedAboutPage = connect(mapStateToProps)(AboutPage);
export { connectedAboutPage as AboutPage };