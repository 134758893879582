import BrowserType from "./BrowserType.jsx";
import GradeLevel from "./GradeLevel.jsx";
import PaperStyle from "./PaperStyle";

class BrowserConfig {
  constructor(browserType) {
    switch (browserType) {
      case BrowserType.MAC_CHROME:
      case BrowserType.MAC_FIREFOX:
      case BrowserType.MAC_OPERA:
      case BrowserType.MAC_SAFARI:
      case BrowserType.MAC_UNKNOWN:
        this.kindergartenDottedPagePaddingTop = "40px";
        this.kindergartenRegularPagePaddingTop = "113.5px";
        this.firstGradeDottedPagePaddingTop = "16px";
        this.firstGradeRegularPagePaddingTop = "111px";
        this.secondGradeDottedPagePaddingTop = "54px";
        this.secondGradeRegularPagePaddingTop = "75.5px";
        break;
      case BrowserType.WINDOWS_CHROME:
      case BrowserType.WINDOWS_FIREFOX:
      case BrowserType.WINDOWS_EDGE:
      case BrowserType.WINDOWS_OPERA:
      case BrowserType.WINDOWS_UNKNOWN:
      case BrowserType.UNKNOWN:
      default:
        this.kindergartenDottedPagePaddingTop = "41.5px";
        this.kindergartenRegularPagePaddingTop = "115px";
        this.firstGradeDottedPagePaddingTop = "17.5px";
        this.firstGradeRegularPagePaddingTop = "112.5px";
        this.secondGradeDottedPagePaddingTop = "54.5px";
        this.secondGradeRegularPagePaddingTop = "76px";
        break;
    }
  }

  getPagePaddingTop(gradeLevel, paperStyle) {
    switch (gradeLevel + "|" + paperStyle) {
      case GradeLevel.KINDERGARTEN + "|" + PaperStyle.DOTTED:
        return this.kindergartenDottedPagePaddingTop;
      case GradeLevel.KINDERGARTEN + "|" + PaperStyle.REGULAR:
        return this.kindergartenRegularPagePaddingTop;
      case GradeLevel.FIRST_GRADE + "|" + PaperStyle.DOTTED:
        return this.firstGradeDottedPagePaddingTop;
      case GradeLevel.FIRST_GRADE + "|" + PaperStyle.REGULAR:
        return this.firstGradeRegularPagePaddingTop;
      case GradeLevel.SECOND_GRADE + "|" + PaperStyle.DOTTED:
        return this.secondGradeDottedPagePaddingTop;
      case GradeLevel.SECOND_GRADE + "|" + PaperStyle.REGULAR:
        return this.secondGradeRegularPagePaddingTop;
    }
  }
}

export default BrowserConfig;
