import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout,
    register,
    forgotPassword,
    resetPassword
};

function login(username, password, redirectedFrom) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push(redirectedFrom);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => { 
                    dispatch(success());
                    history.push('/login');
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function forgotPassword(email) {
    return dispatch => {
        dispatch(request({ email }));

        userService.sendForgotPasswordEmail(email)
            .then(
                email => {
                    dispatch(success(email));
                    dispatch(alertActions.success("Successfully sent password reset email"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(email) { return { type: userConstants.FORGOT_PASSWORD_REQUEST, email } }
    function success(email) { return { type: userConstants.FORGOT_PASSWORD_SUCCESS, email } }
    function failure(error) { return { type: userConstants.FORGOT_PASSWORD_FAILURE, error } }
}

function resetPassword(email, password, token) {
    return dispatch => {
        dispatch(request({ email }));

        userService.resetPassword(email, password, token)
            .then(
                email => {
                    dispatch(success(email));
                    dispatch(alertActions.success("Successfully reset password"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(email) { return { type: userConstants.RESET_PASSWORD_REQUEST, email } }
    function success(email) { return { type: userConstants.RESET_PASSWORD_SUCCESS, email } }
    function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILURE, error } }
}