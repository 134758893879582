// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-subscriptions-breadcrumbs {
    font-size: 22px;
    margin: 10px 0 !important;
    text-align: left;
}

.product-subscriptions-list {
    padding-bottom: 30px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
}

.product-subscriptions-header {
    font-size: 30px;
    font-weight: bold;
}

.product-subscriptions-row {
    margin-top: 10px !important;
    border-radius: 5px;
    font-size: 24px;
}

.empty-subscriptions-row, .loading-subscriptions-row {
    text-align: center;
    margin-top: 10px !important;
}

.empty-subscriptions-message, .loading-subscriptions-message {
    font-size: 26px;
    color: #ccc;
}`, "",{"version":3,"sources":["webpack://./css/product-subscriptions/product-subscriptions.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,WAAW;AACf","sourcesContent":[".product-subscriptions-breadcrumbs {\n    font-size: 22px;\n    margin: 10px 0 !important;\n    text-align: left;\n}\n\n.product-subscriptions-list {\n    padding-bottom: 30px;\n    padding-top: 10px;\n    border-top: 1px solid #ddd;\n}\n\n.product-subscriptions-header {\n    font-size: 30px;\n    font-weight: bold;\n}\n\n.product-subscriptions-row {\n    margin-top: 10px !important;\n    border-radius: 5px;\n    font-size: 24px;\n}\n\n.empty-subscriptions-row, .loading-subscriptions-row {\n    text-align: center;\n    margin-top: 10px !important;\n}\n\n.empty-subscriptions-message, .loading-subscriptions-message {\n    font-size: 26px;\n    color: #ccc;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
