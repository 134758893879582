import Browser from "./Browser.jsx";
import GradeLevel from "./GradeLevel.jsx";
import LetterStyle from "./LetterStyle.jsx";
import React from "react";
import PaperStyle from "./PaperStyle";

class Page extends React.Component {
  // TODO: This is super hacky, but seems to get the job done for now.
  // It sounds like the better approach would be to hoist all of this state
  // into the parent.
  componentDidUpdate() {
    let input = document.getElementById('input');

    let originalText = this.props.inputText;
    if (!originalText) {
      return;
    }

    let truncatedText = originalText;
    while (truncatedText.length >= 0 && input.scrollHeight > input.clientHeight) {
      truncatedText = truncatedText.substring(0, truncatedText.length - 1);
      input.value = truncatedText;
    }

    if (truncatedText !== originalText) {
      this.props.handleTextUpdate(truncatedText);
    }
  }

  render() {
    let pageClass = "page ";
    let pageBackgroundClass = "page-background ";
    switch (this.props.gradeLevel) {
      case GradeLevel.KINDERGARTEN:
        pageClass += "page-kindergarten";
        pageBackgroundClass += "page-kindergarten";
        break;
      case GradeLevel.FIRST_GRADE:
        pageClass += "page-first-grade";
        pageBackgroundClass += "page-first-grade";
        break;
      case GradeLevel.SECOND_GRADE:
        pageClass += "page-second-grade";
        pageBackgroundClass += "page-second-grade";
        break;
    }
    switch (this.props.paperStyle) {
      case PaperStyle.DOTTED:
        pageClass += " page-paper-dotted";
        pageBackgroundClass += " page-paper-dotted";
        break;
      case PaperStyle.REGULAR:
        pageClass += " page-paper-regular";
        pageBackgroundClass += " page-paper-regular";
        break;
    }
    return (
      <div className={pageClass}>
        <div className={pageBackgroundClass} />
        {this._getTextRows()}
      </div>
    );
  }

  _getTextRows(): React.Node {
    const inputText = this.props.inputText;

    let style;
    switch (this.props.letterStyle) {
      case LetterStyle.ARROWS:
        style = 'arrows';
        break;
      case LetterStyle.DOTS:
        style = 'dots';
        break;
      case LetterStyle.SHADED:
        style = 'shaded';
        break;
      case LetterStyle.BLANK:
        style = 'blank';
        break;
    }

    const rows = [this._getInputRow(inputText, style)];

    // Set placeholder in browser-compatible way
    if (inputText === "") {
      rows.push(this._getBackgroundRow("Type here...", 'shaded'));
    } else {
      switch (this.props.letterStyle) {
        case LetterStyle.ARROWS:
          rows.push(this._getBackgroundRow(inputText, 'shaded'));
          rows.push(this._getBackgroundRow(inputText, 'dots'));
          break;
        case LetterStyle.DOTS:
          rows.push(this._getBackgroundRow(inputText, 'shaded'));
          break;
      }
    }

    const config = Browser.getConfig();
    const paddingTop = config.getPagePaddingTop(this.props.gradeLevel, this.props.paperStyle);

    return (
      <div className="page-content">
        <div className="input-body" style={{ paddingTop: paddingTop }}>
          {rows}
        </div>
        <div className="input-blocker" style={{ paddingTop: paddingTop }} />
      </div>
    );
  }

  _getInputRow(inputText: string, style: string): React.Node {
    return (
      <textarea
        id="input"
        className={'worksheet-row input ' + style}
        value={inputText ? inputText : ""}
        key="input-row"
        onChange={this._onChangeInputText.bind(this)}
        wrap="hard"
      />
    );
  }

  _getBackgroundRow(inputText: string, style: string): React.Node {
    return (
      <textarea
        className={'worksheet-row ' + style}
        value={inputText ? inputText : ""}
        readOnly={true}
        key={'background-row ' + style}
        wrap="hard"
      />
    );
  }

  _onChangeInputText(event: Object): void {
    const nativeEvent = event.nativeEvent;
    if (nativeEvent.target.scrollHeight > nativeEvent.target.clientHeight) {
      this.props.handleTextUpdate(this.props.inputText)
    } else {
      this.props.handleTextUpdate(event.target.value)
    }
  }
}

export default Page;
