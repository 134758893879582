import React, { Component } from "react";
import { connect } from 'react-redux';
import { contentService } from '../_services';
import { AudiencePage } from '../AudiencePage';
import {PageLoader} from "../PageLoader";
import {ErrorPage} from "../ErrorPage";

import "../../css/therapists/therapists.css";
import "../../css/audience/audience.css";

class TherapistsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestFailed: false
        };
    }

    componentDidMount() {
        contentService.getPageContent('therapists')
            .then(content => {
                this.setState({
                    content: content
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    errorMessage: error
                })
            })
    }

    render() {
        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage}/>
            )
        }
        if (!this.state.content) {
            return (
                <PageLoader />
            )
        }

        const therapistsContent = this.state.content;
        return (
            <div className="therapists-content">
                <AudiencePage pageContent={therapistsContent} pageTitle={"For Therapists"} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedTherapistsPage = connect(mapStateToProps)(TherapistsPage);
export { connectedTherapistsPage as TherapistsPage };