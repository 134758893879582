import React, { Component } from "react";
import { connect } from 'react-redux';
import { contentService } from '../_services';
import { AudiencePage } from '../AudiencePage';
import {PageLoader} from "../PageLoader";
import {ErrorPage} from "../ErrorPage";

import "../../css/teachers/teachers.css";
import "../../css/audience/audience.css";

class TeachersPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            requestFailed: false
        };
    }

    componentDidMount() {
        contentService.getPageContent('teachers')
            .then(content => {
                this.setState({
                    content: content
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    errorMessage: error
                })
            })
    }

    render() {
        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage}/>
            )
        }
        if (!this.state.content) {
            return (
                <PageLoader />
            )
        }

        const teachersContent = this.state.content;
        return (
            <div className="teachers-content">
                <AudiencePage pageContent={teachersContent} pageTitle={"For Teachers"} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedTeachersPage = connect(mapStateToProps)(TeachersPage);
export { connectedTeachersPage as TeachersPage };