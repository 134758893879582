import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { forgotPassword } from './forgot-password.reducer';
import { resetPassword } from './reset-password.reducer';

const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    alert,
    forgotPassword,
    resetPassword
});

export default rootReducer;