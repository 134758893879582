import ConfigPanel from "./ConfigPanel.jsx";
import ExportButton from "./ExportButton.jsx";
import GradeLevel from "./GradeLevel.jsx";
import LetterStyle from "./LetterStyle.jsx";
import Page from "./Page.jsx";
import React, { Component } from "react";
import { connect } from "react-redux";

import "../../css/worksheet-maker/worksheet-maker.css";
import { ErrorPage } from "../ErrorPage";
import { Link } from "react-router-dom";
import { userService } from "../_services";
import { PageLoader } from "../PageLoader";
import PaperStyle from "./PaperStyle";

const GRADE_LEVEL_OPTIONS_BY_LABEL = {
  "Ages 5-7 (K)": { id: GradeLevel.KINDERGARTEN, label: "K (Ages 5-7)" },
  "Ages 6-8 (1st)": { id: GradeLevel.FIRST_GRADE, label: "1st (Ages 6-8)" },
  "Ages 7-9 (2nd)": { id: GradeLevel.SECOND_GRADE, label: "2nd (Ages 7-9)" }
};

const LETTER_STYLE_CONFIG_SECTION = {
  id: "letter_style",
  title: "Letter Options",
  options: [
    { id: LetterStyle.ARROWS, label: "Arrows" },
    { id: LetterStyle.DOTS, label: "Dots" },
    { id: LetterStyle.SHADED, label: "Shaded" },
    { id: LetterStyle.BLANK, label: "Blank" }
  ]
};

const PAPER_STYLE_CONFIG_SECTION = {
  id: "paper_style",
  title: "Paper",
  options: [
    { id: PaperStyle.DOTTED, label: "Dotted" },
    { id: PaperStyle.REGULAR, label: "Regular" }
  ]
};

const ERROR_MESSAGE =
  "We couldn't find a worksheet maker subscription under your account. If you previously held a subscription, it may have expired. If you think you’ve received this notice in error, please connect with a BNM representative through the Contact page.";

class WorksheetMakerPage extends Component {
  constructor(props) {
    super(props);
    this.props.updateUserAuthenticationState();

    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.handleTextUpdate = this.handleTextUpdate.bind(this);

    this.state = {
      requestFailed: false,
      user: this.props.user,
      userSubscriptions: [],
      gradeLevelConfig: {
        id: "grade_level",
        title: "Grade Level",
        options: []
      },
      requestCompleted: false,
      inputText: ""
    };
  }

  handleTextUpdate(text) {
    this.setState({ inputText: text });
  }

  handleOptionClick(sectionId, optionId) {
    let selectedOptionIds = {};
    for (let selectedOptionId in this.state.selectedOptionIds) {
      selectedOptionIds[selectedOptionId] = this.state.selectedOptionIds[
        selectedOptionId
      ];
    }
    selectedOptionIds[sectionId] = optionId;
    this.setState({ selectedOptionIds: selectedOptionIds });
    this.handleTextUpdate(this.state.inputText)
  }

  extractGradeLevelsOptions(userSubscriptions) {
    let gradeLevelOptions = [];
    let gradeLevelOptionsMap = {};
    userSubscriptions.map(function(userSubscription, i) {
      userSubscription.subscription.grade_levels.map(function(gradeLevel, i) {
        if (!gradeLevelOptionsMap[gradeLevel["title"]]) {
          gradeLevelOptions.push(
            GRADE_LEVEL_OPTIONS_BY_LABEL[gradeLevel["title"]]
          );
          gradeLevelOptionsMap[gradeLevel["title"]] = true;
        }
      });
    });
    return gradeLevelOptions;
  }

  componentDidMount() {
    userService.getUserWorksheetMakerSubscriptions(this.state.user.id).then(
      userSubscriptions => {
        let gradeLevelOptions = this.extractGradeLevelsOptions(
          userSubscriptions
        );
        let gradeLevelConfig = {
          id: "grade_level",
          title: "Grade Level",
          options: gradeLevelOptions
        };

        let selectedOptionIds = {};
        [PAPER_STYLE_CONFIG_SECTION, gradeLevelConfig, LETTER_STYLE_CONFIG_SECTION].forEach(section => {
          if (section.options.length > 0) {
            selectedOptionIds[section.id] = section.options[0].id;
          }
        });

        this.setState({
          userSubscriptions: userSubscriptions,
          requestCompleted: true,
          selectedOptionIds: selectedOptionIds,
          gradeLevelConfig: gradeLevelConfig
        });
      },
      error => {
        this.setState({
          requestFailed: true,
          requestCompleted: true
        });
      }
    );
  }

  render() {
    if (this.state.requestFailed) {
      return <ErrorPage errorMessage={ERROR_MESSAGE} />;
    }
    if (!this.state.requestCompleted) {
      return <PageLoader />;
    }

    if (this.state.gradeLevelConfig.options.length === 0) {
      return <ErrorPage errorMessage={ERROR_MESSAGE} />;
    }

    return (
      <div className="worksheet-maker">
        <div className="container">
          <div className="worksheet-maker-breadcrumbs row">
            <div className="col-md-12">
              <Link to="/subscriptions" className="">
                Subscriptions
              </Link>
              <span> / Worksheet Maker</span>
            </div>
          </div>
        </div>
        <div className="worksheet-maker-container container">
          <div className="row">
            <div className="col-md-3 worksheet-maker-controls">
              <ConfigPanel
                sections={[
                  PAPER_STYLE_CONFIG_SECTION,
                  this.state.gradeLevelConfig,
                  LETTER_STYLE_CONFIG_SECTION
                ]}
                selectedOptionIds={this.state.selectedOptionIds}
                handleOptionClick={this.handleOptionClick}
              />
              <ExportButton
                inputText={this.state.inputText}
                gradeLevel={this.state.selectedOptionIds.grade_level}
                letterStyle={this.state.selectedOptionIds.letter_style}
                paperStyle={this.state.selectedOptionIds.paper_style}
              />
            </div>
            <div className="col-md-9 worksheet-maker-page">
              <Page
                handleTextUpdate={this.handleTextUpdate}
                inputText={this.state.inputText}
                gradeLevel={this.state.selectedOptionIds.grade_level}
                letterStyle={this.state.selectedOptionIds.letter_style}
                paperStyle={this.state.selectedOptionIds.paper_style}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

const connectedWorksheetMakerPage = connect(mapStateToProps)(
  WorksheetMakerPage
);
export { connectedWorksheetMakerPage as WorksheetMakerPage };
