import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { orderService } from '../_services';
import { ErrorPage } from '../ErrorPage';
import { PageLoader } from '../PageLoader';

import "../../css/receipt/receipt.css";

class ReceiptPage extends Component {
    constructor(props) {
        super(props);
        this.props.updateUserAuthenticationState();
        
        this.state = {
            requestFailed: false,
            confirmationCode: props.match.params.confirmationCode
        };
    }

    componentDidMount() {
        orderService.getOrder(this.state.confirmationCode)
            .then(order => {
                this.setState({
                    order: order
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    errorMessage: error.message,
                    errorIconClass: error.iconClasses,
                })
            })
    }

    orderContainsSubscription(order) {
        let result = false;
        {order.products.map(function(product, i){
            if (product.subscription === true) {
                result =  true;
            }
        })}
        return result;
    }

    render() {
        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage} iconClasses={this.state.errorIconClass}/>
            )
        }
        if (!this.state.order) {
            return (
                <PageLoader />
            )
        }

        let subscriptionsInOrder = this.orderContainsSubscription(this.state.order);
        const hideSubscriptionButton = subscriptionsInOrder ? '' : 'hide-element';

        return (
            <div className="receipt-container row">
                <div className="container">
                    <i className="far fa-check-circle receipt-main-icon"/>
                    <span className="receipt-title">Thank you for your order</span>
                    <span className="receipt-title">BNM is honored to be a part of  your learning community</span>
                    <span className="receipt-subtitle">You will receive an email with your full order information</span>
                    <div className="order-details well">
                        <span className="order-details-title">Order details</span>
                        <div className="order-number-container">
                            <span className="order-details-label">Order confirmation code:</span>
                            <span className="order-details-value">{this.state.order.confirmation_code}</span>
                        </div>
                        <div className="order-date-container">
                            <span className="order-details-label">Date:</span>
                            <span className="order-details-value">{this.state.order.created}</span>
                        </div>
                        <div className="order-payment-method-container">
                            <span className="order-details-label">Payment method:</span>
                            <span className="order-details-value">{this.state.order.payment_method}</span>
                        </div>
                        <div className="order-total-price-container">
                            <span className="order-details-label">Total:</span>
                            <span className="order-details-value">${this.state.order.amount}</span>
                        </div>
                    </div>
                    <div className="receipt-buttons">
                        <Link to="/products">
                            <button className="btn btn-primary-dark">
                                <i className="fa fa-arrow-left arrow-left-icon"/>Back to Shopping
                            </button>
                        </Link>
                        <Link to="/login" className="logout-link">
                            <button className="btn btn-primary-dark logout-button">
                                Logout
                            </button>
                        </Link>
                        <Link to="/subscriptions" className={"my-account-link " + hideSubscriptionButton}>
                            <button className="btn btn-primary-dark my-account-button">
                                View Subscriptions <i className="fa fa-arrow-right arrow-right-icon"/>
                            </button>
                        </Link>
                    </div>
                    <div className="mobile-receipt-buttons">
                        <Link to="/products">
                            <button className="btn btn-primary-dark">
                                <i className="fa fa-arrow-left arrow-left-icon"/>Shopping
                            </button>
                        </Link>
                        <Link to="/login" className="logout-link">
                            <button className="btn btn-primary-dark logout-button">
                                Logout
                            </button>
                        </Link>
                        <Link to="/subscriptions" className={"my-account-link " + hideSubscriptionButton}>
                            <button className="btn btn-primary-dark my-account-button">
                                Subscriptions <i className="fa fa-arrow-right arrow-right-icon"/>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedReceiptPage = connect(mapStateToProps)(ReceiptPage);
export { connectedReceiptPage as ReceiptPage };