// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-container {
    margin-top: 40px;
    margin-bottom: 40px;
}

.register-title {
    margin-bottom: 10px !important;
}

.register-container label, .register-container button, .register-container a, .register-container input, .register-container select, .alert {
    font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./css/register/register.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".register-container {\n    margin-top: 40px;\n    margin-bottom: 40px;\n}\n\n.register-title {\n    margin-bottom: 10px !important;\n}\n\n.register-container label, .register-container button, .register-container a, .register-container input, .register-container select, .alert {\n    font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
