// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export-button {
  background-color: #fefefe;
  border-radius: 10px;
  box-shadow: 0 1px 10px grey;
  cursor: pointer;
  font-family: 'BNM', sans-serif;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 12px 24px 12px 24px;
  width: 170px;
}

.export-error-message-box {
  background: rgba(256, 0, 0, 0.1);
  color: red;
  border: 1px solid rgba(256, 0, 0, 0.7);
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  font-size: 20px;
  width: 170px;
}

.export-error-message-box i {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./css/worksheet-maker/ExportButton.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,2BAA2B;EAC3B,eAAe;EACf,8BAA8B;EAC9B,eAAe;EACf,eAAe;EACf,mBAAmB;EACnB,4BAA4B;EAC5B,YAAY;AACd;;AAEA;EACE,gCAAgC;EAChC,UAAU;EACV,sCAAsC;EACtC,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".export-button {\n  background-color: #fefefe;\n  border-radius: 10px;\n  box-shadow: 0 1px 10px grey;\n  cursor: pointer;\n  font-family: 'BNM', sans-serif;\n  font-size: 18px;\n  margin-top: 5px;\n  margin-bottom: 20px;\n  padding: 12px 24px 12px 24px;\n  width: 170px;\n}\n\n.export-error-message-box {\n  background: rgba(256, 0, 0, 0.1);\n  color: red;\n  border: 1px solid rgba(256, 0, 0, 0.7);\n  border-radius: 5px;\n  padding: 10px;\n  margin-top: 20px;\n  font-size: 20px;\n  width: 170px;\n}\n\n.export-error-message-box i {\n  margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
