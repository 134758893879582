import detect from "detect.js";
import BrowserConfig from "./BrowserConfig.jsx";
import BrowserType from "./BrowserType.jsx";

class Browser {
  static getConfig() {
    const type = Browser.getType();
    return new BrowserConfig(type);
  }

  static getType() {
    const userAgent = detect.parse(navigator.userAgent);
    if (userAgent.os.family.includes("Mac")) {
      switch (userAgent.browser.family) {
        case "Chrome":
          return BrowserType.MAC_CHROME;
        case "Firefox":
          return BrowserType.MAC_FIREFOX;
        case "Opera":
          return BrowserType.MAC_OPERA;
        case "Safari":
          return BrowserType.MAC_SAFARI;
        default:
          return BrowserType.MAC_UNKNOWN;
      }
    } else if (userAgent.os.family.includes("Windows")) {
      switch (userAgent.browser.family) {
        case "Chrome":
          return BrowserType.WINDOWS_CHROME;
        case "Edge":
          return BrowserType.WINDOWS_EDGE;
        case "Firefox":
          return BrowserType.WINDOWS_FIREFOX;
        case "Opera":
          return BrowserType.WINDOWS_OPERA;
        default:
          return BrowserType.WINDOWS_UNKNOWN;
      }
    } else {
      return BrowserType.UNKNOWN;
    }
  }
}

export default Browser;
