import React from "react";
import {worksheetService} from "../_services";

class ExportButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      showExportError: false,
      exportErrorMessage: 'Error exporting page'
    };
  }
  render() {
    return (
      <div>
        <div
          className={"export-button"}
          onClick={() => this.exportPdf(this.props.inputText, this.props.letterStyle, this.props.paperStyle, this.props.gradeLevel)}
        >
          Export PDF
        </div>
        {
            this.state.showExportError && <div className="export-error-message-box">
              <i className="fas fa-exclamation-circle" />
              <span>{this.state.exportErrorMessage}</span>
            </div>
        }
      </div>
    );
  }

  async exportPdf(text: string, letterStyle: string, paperStyle: string, gradeLevel: string) {
    if (this.state.processing) {
        return;
    }

    this.setState({
        processing: true,
        showExportError: false
    });
    worksheetService.createWorksheet(text, letterStyle, paperStyle, gradeLevel).then(
            worksheet => {
                let link=document.createElement('a');
                link.href=window.URL.createObjectURL(worksheet);
                link.download="worksheet.pdf";
                link.click();

                this.setState({
                    processing: false,
                });
            },
            error => {
                this.setState({
                    showExportError: true,
                    processing: false
                })
            }
        )
  }
}

export default ExportButton;
