import {authHeader} from "../_helpers";

const API_BASE = process.env.API_URL;

export const subscriptionService = {
    getUserSubscriptionFile,
};

function getUserSubscriptionFile(userId, userSubscriptionId, fileId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    let url = API_BASE + '/users/' + userId + '/subscriptions/' + userSubscriptionId + '/files/' + fileId;
    return fetch(url, requestOptions)
        .then(response => {
            if (response.status === 403) {
                return Promise.reject("You don't have permission to view this page.");
            }
            if (!response.ok) {
                return Promise.reject("Uh oh! Looks like we're having trouble loading this file.");
            }

            return response.blob();
        });
}
