import React from "react";

const API_BASE = process.env.API_URL;

export const cartService = {
    attachCartQuantities,
    decreaseItemQuantity,
    extractBundledCartItems,
    getCartItems,
    increaseItemQuantity,
    removeItemFromCart,
    clearCart
};

const cartStorageKey = 'bnm-cart-items';
let inMemoryCache = {};
let itemStore = localStorage;

if (!localStorage) {
  console.warn("Local Storage is not supported, data will not be persisted");

  itemStore = {};
  itemStore.getItem = function(key) {
    return inMemoryCache[key] ? inMemoryCache[key] : "{}";
  };
  itemStore.setItem = function(key, value) {
    inMemoryCache[key] = value;
  }
}

function increaseItemQuantity(item) {
    let storedCartItems = JSON.parse(itemStore.getItem(cartStorageKey));
    if (!storedCartItems) {
        storedCartItems = {}
    }

    if (storedCartItems[item.id]) {
        storedCartItems[item.id].quantity++
    }
    else {
        storedCartItems[item.id] = {
            quantity:1,
            timestamp: Math.floor(Date.now() / 1000)
        }
    }
    itemStore.setItem(cartStorageKey, JSON.stringify(storedCartItems));
    return Promise.resolve('Successfully increased item quantity: ' + item.id);
}


function decreaseItemQuantity(item) {
    let storedCartItems = JSON.parse(itemStore.getItem(cartStorageKey));
    if (storedCartItems[item.id]) {
        storedCartItems[item.id].quantity--;
    }
    itemStore.setItem(cartStorageKey, JSON.stringify(storedCartItems));
    return Promise.resolve('Successfully decreased item quantity: ' + item.id);
}

function removeItemFromCart(item) {
    let storedCartItems = JSON.parse(itemStore.getItem(cartStorageKey));

    if (storedCartItems) {
        delete storedCartItems[item.id]
    }
    itemStore.setItem(cartStorageKey, JSON.stringify(storedCartItems));
    return Promise.resolve('Successfully removed item from cart: ' + item.id);
}

function clearCart() {
    itemStore.setItem(cartStorageKey, JSON.stringify({}));
    return Promise.resolve('Successfully cleared cart.');
}

function getCartItems() {
    let storedCartItems = JSON.parse(itemStore.getItem(cartStorageKey));
    if (!storedCartItems) {
        storedCartItems = {}
    }
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    let idList = Object.keys(storedCartItems).join(", ");

    return fetch(API_BASE + '/products?ids=' + idList, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject("Uh oh! Looks like we're having trouble loading this page.");
            }

            return response.json();
        });
}

function attachCartQuantities(cartItems) {
    let storedCartItems = JSON.parse(itemStore.getItem(cartStorageKey));
    if (!storedCartItems) {
        storedCartItems = {}
    }
    cartItems.map(function(item, i) {
        item.quantity = storedCartItems[item.id].quantity
    });

    return cartItems;
}

function extractBundledCartItems(cartItems) {
    let bundledCartItems = {}
    cartItems.map(function(cartItem, i) {
        cartItem.bundled_products.map(function(bundled_product, i) {
            if (bundled_product.threshold > 0) {
                let bundled_product_quantity = Math.floor(cartItem.quantity / bundled_product.threshold)
                if (bundled_product_quantity > 0) {
                    if (bundledCartItems[bundled_product.product.id]) {
                        bundledCartItems[bundled_product.product.id].quantity += bundled_product_quantity
                    }
                    else {
                        bundled_product.product.quantity = bundled_product_quantity
                        bundledCartItems[bundled_product.product.id] = bundled_product.product
                    }
                }
            }
        });
    });

    return Object.values(bundledCartItems);
}
