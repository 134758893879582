// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-landing-container {
    margin-top: 30px;
    margin-bottom: 30px;
}

.subscription-landing-card {
    text-align: center;
    display: block;
    font-size: 48px;
    background: #eee;
    border-radius: 15px;
    color: #999;
    box-shadow: 1px 1px 5px 0;
    margin-bottom: 30px;
    border: 3px solid rgba(0,0,0,0);
}

.subscription-landing-card-blue:hover {
    border: 3px solid rgba(0,192,243, 0.5);
    color: rgba(0,192,243, 0.8);
}

.subscription-landing-card-green:hover {
    border: 3px solid rgba(128,195,66, 0.5);
    color: rgba(128,195,66, 0.8);
}

.subscription-landing-card-orange:hover {
    border: 3px solid rgba(243,114,36, 0.5);
    color: rgba(243,114,36, 0.8);
}

.subscription-landing-card:hover {
    box-shadow: 1px 1px 8px 0;
    text-decoration: none !important;
    /*color: #999 !important;*/
}

.subscription-landing-icon {
    font-size: 130px;
    margin-top: 20px;
}

.subscription-landing-card-label {
    display: block;
    font-size: 44px;
    font-family: "Futura PT Condensed Bold", sans-serif;
}`, "",{"version":3,"sources":["webpack://./css/subscription-landing/subscription-landing.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,+BAA+B;AACnC;;AAEA;IACI,sCAAsC;IACtC,2BAA2B;AAC/B;;AAEA;IACI,uCAAuC;IACvC,4BAA4B;AAChC;;AAEA;IACI,uCAAuC;IACvC,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;IACzB,gCAAgC;IAChC,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mDAAmD;AACvD","sourcesContent":[".subscription-landing-container {\n    margin-top: 30px;\n    margin-bottom: 30px;\n}\n\n.subscription-landing-card {\n    text-align: center;\n    display: block;\n    font-size: 48px;\n    background: #eee;\n    border-radius: 15px;\n    color: #999;\n    box-shadow: 1px 1px 5px 0;\n    margin-bottom: 30px;\n    border: 3px solid rgba(0,0,0,0);\n}\n\n.subscription-landing-card-blue:hover {\n    border: 3px solid rgba(0,192,243, 0.5);\n    color: rgba(0,192,243, 0.8);\n}\n\n.subscription-landing-card-green:hover {\n    border: 3px solid rgba(128,195,66, 0.5);\n    color: rgba(128,195,66, 0.8);\n}\n\n.subscription-landing-card-orange:hover {\n    border: 3px solid rgba(243,114,36, 0.5);\n    color: rgba(243,114,36, 0.8);\n}\n\n.subscription-landing-card:hover {\n    box-shadow: 1px 1px 8px 0;\n    text-decoration: none !important;\n    /*color: #999 !important;*/\n}\n\n.subscription-landing-icon {\n    font-size: 130px;\n    margin-top: 20px;\n}\n\n.subscription-landing-card-label {\n    display: block;\n    font-size: 44px;\n    font-family: \"Futura PT Condensed Bold\", sans-serif;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
