import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import "../../css/footer/footer.css";

const STATIC_IMAGES_PATH = process.env.STATIC_IMAGES_PATH;
const BASE_URL = process.env.API_URL.replace(/api\/v1/,"");

class Footer extends Component {
    render() {
        return (
            <div className="bnm-footer hidden-print">
                <div className="image-row">
                    <div className="footer-line line-left" />
                    <Link to="/">
                        <div className="logo-image">
                            <img src={STATIC_IMAGES_PATH + "logo.png"} />
                        </div>
                    </Link>
                    <div className="footer-line line-right" />
                </div>
                <ul className="footer">
                    <li><Link to="/about" className="btn btn-link">ABOUT</Link></li>
                    <li><Link to="/products" className="btn btn-link">PRODUCTS</Link></li>
                    <li><Link to="/testimonials" className="btn btn-link">TESTIMONIALS</Link></li>
                    <li><Link to="/subscriptions" className="btn btn-link">SUBSCRIPTIONS</Link></li>
                    <li><Link to="/contact" className="btn btn-link">CONTACT</Link></li>
                    <li><Link to="/terms-and-conditions" className="btn btn-link">TERMS AND CONDITIONS</Link></li>
                    <li><a href={BASE_URL + "bnm-files/bnm-media-kit.pdf"} target="_blank" className="btn btn-link">MEDIA</a></li>
                    <li><Link to="/my-account" className="btn btn-link">MY ACCOUNT</Link></li>
                </ul>
                <div className="social-links-container">
                    <a href="https://www.facebook.com/becauseneatnessmatters/"><i className="fab fa-facebook"/></a>
                    <a href="https://www.linkedin.com/in/martha-theisen/"><i className="fab fa-linkedin"/></a>
                    <a href="https://www.instagram.com/writingforallbnm/"><i className="fab fa-instagram"/></a>
                    <a href="https://homelearningstore.com/storefront.php?id=34"><i className="fas fa-house-user"/></a>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedFooter = connect(mapStateToProps)(Footer);
export { connectedFooter as Footer };