// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bnm-footer {
    padding-top: 50px;
    padding-bottom: 60px;
    background-color: #02254A;
    position:absolute;
	width:100%;
	bottom:0;
    font-family: "FF Uberhand Pro Medium", sans-serif;
}

.bnm-footer ul {
    text-align: center;
}

.bnm-footer li {
    padding-left: 30px;
}

.bnm-footer .logo-image {
    text-align: center;
    display: inline-block;
}

ul.footer li {
    display: inline;
    list-style-type: none;
    margin: 0;
}

ul.footer {
    padding: 0;
}

ul.footer li a {
    color: #FFF;
    font-weight: bold;
    text-decoration: none !important;
    padding: 20px;
    display: inline-block;
}

.image-row {
    text-align: center;
}

.footer-line {
    width: 35%;
    height: 4px;
    border: solid #fff 2px;
    display: inline-block;
    border-radius: 10px;
}

.social-links-container {
    width: 100%;
    text-align: center;
    margin-top: 60px;
}

.social-links-container i {
    color: #fff;
    font-size:20px;
    margin: 0 10px;
}

@media only screen and (max-width: 750px) {
    ul.footer li {
        display: block;
        padding-left: 0;
    }

    ul.footer li>a {
        padding: 5px;
    }

    .footer-line {
        width: 25%;
    }
}`, "",{"version":3,"sources":["webpack://./css/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,yBAAyB;IACzB,iBAAiB;CACpB,UAAU;CACV,QAAQ;IACL,iDAAiD;AACrD;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,qBAAqB;IACrB,SAAS;AACb;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,gCAAgC;IAChC,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,sBAAsB;IACtB,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,cAAc;AAClB;;AAEA;IACI;QACI,cAAc;QACd,eAAe;IACnB;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".bnm-footer {\n    padding-top: 50px;\n    padding-bottom: 60px;\n    background-color: #02254A;\n    position:absolute;\n\twidth:100%;\n\tbottom:0;\n    font-family: \"FF Uberhand Pro Medium\", sans-serif;\n}\n\n.bnm-footer ul {\n    text-align: center;\n}\n\n.bnm-footer li {\n    padding-left: 30px;\n}\n\n.bnm-footer .logo-image {\n    text-align: center;\n    display: inline-block;\n}\n\nul.footer li {\n    display: inline;\n    list-style-type: none;\n    margin: 0;\n}\n\nul.footer {\n    padding: 0;\n}\n\nul.footer li a {\n    color: #FFF;\n    font-weight: bold;\n    text-decoration: none !important;\n    padding: 20px;\n    display: inline-block;\n}\n\n.image-row {\n    text-align: center;\n}\n\n.footer-line {\n    width: 35%;\n    height: 4px;\n    border: solid #fff 2px;\n    display: inline-block;\n    border-radius: 10px;\n}\n\n.social-links-container {\n    width: 100%;\n    text-align: center;\n    margin-top: 60px;\n}\n\n.social-links-container i {\n    color: #fff;\n    font-size:20px;\n    margin: 0 10px;\n}\n\n@media only screen and (max-width: 750px) {\n    ul.footer li {\n        display: block;\n        padding-left: 0;\n    }\n\n    ul.footer li>a {\n        padding: 5px;\n    }\n\n    .footer-line {\n        width: 25%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
