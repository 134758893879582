// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.therapists-content .banner-image {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-attachment: inherit;
    background-position-y: 30%;
    opacity: 0.80;
}`, "",{"version":3,"sources":["webpack://./css/therapists/therapists.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,0BAA0B;IAC1B,aAAa;AACjB","sourcesContent":[".therapists-content .banner-image {\n    width: 100%;\n    height: 300px;\n    background-size: cover;\n    background-attachment: inherit;\n    background-position-y: 30%;\n    opacity: 0.80;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
