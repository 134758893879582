// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videos-content {
    font-family: "FF Uberhand Pro Medium", sans-serif;
}

.video-subscriptions-breadcrumbs {
    font-size: 22px;
    margin: 10px 0 !important;
    text-align: left;
    font-family: "Futura PT Condensed Book", sans-serif;
}

.video-list {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    max-height: 500px;
    overflow: scroll;
}

.video-row {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px #EEE solid;
}

.video-row .video-thumbnail {
    max-width: 100%;
}

.video-row .video-title {
    font-size: 20px;
}

.selected-video {
    background: #eee;
}

.video-player {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    text-align: center;
}

.video-player div {
    display: inline-block;
}`, "",{"version":3,"sources":["webpack://./css/video-subscriptions/video-subscriptions.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;AACrD;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,gBAAgB;IAChB,mDAAmD;AACvD;;AAEA;IACI,2BAA2B;IAC3B,8BAA8B;IAC9B,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".videos-content {\n    font-family: \"FF Uberhand Pro Medium\", sans-serif;\n}\n\n.video-subscriptions-breadcrumbs {\n    font-size: 22px;\n    margin: 10px 0 !important;\n    text-align: left;\n    font-family: \"Futura PT Condensed Book\", sans-serif;\n}\n\n.video-list {\n    margin-top: 10px !important;\n    margin-bottom: 10px !important;\n    max-height: 500px;\n    overflow: scroll;\n}\n\n.video-row {\n    padding: 10px;\n    cursor: pointer;\n    border-bottom: 1px #EEE solid;\n}\n\n.video-row .video-thumbnail {\n    max-width: 100%;\n}\n\n.video-row .video-title {\n    font-size: 20px;\n}\n\n.selected-video {\n    background: #eee;\n}\n\n.video-player {\n    margin-top: 10px !important;\n    margin-bottom: 10px !important;\n    text-align: center;\n}\n\n.video-player div {\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
