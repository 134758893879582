import {authHeader} from "../_helpers";

const API_BASE = process.env.API_URL;

export const contentService = {
    getPageContent
};

function getPageContent(pageName) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(API_BASE + '/cms/' + pageName, requestOptions)
        .then(response => {
            if (!response.ok) {
                return response.json().then(json => {
                    let message = json.detail ? json.detail : "Uh oh! Looks like we're having trouble loading this page.";
                    return Promise.reject(message);
                });
            }

            return response.json();
        });
}
