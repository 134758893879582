import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import "../../css/header/header.css";

const STATIC_IMAGES_PATH = process.env.STATIC_IMAGES_PATH;

class Header extends Component {
    burgerToggle() {
		let linksEl = document.querySelector('.narrowLinks');
		if (linksEl.style.display === 'inline-block') {
			linksEl.style.display = 'none';
		} else {
			linksEl.style.display = 'inline-block';
		}
	}

    render() {
        return (
            <div className="bnm-header hidden-print">
                <div className="banner">
                    <Link to="/">
                        <div className="logo-image">
                            <img src={STATIC_IMAGES_PATH + "logo.png"} />
                        </div>
                    </Link>
                </div>
                <div className="nav-menu navWide">
                    <ul className="header wideDiv">
                        <li><Link to="/" className="btn btn-link">HOME</Link></li>
                        <li><Link to="/about" className="btn btn-link">ABOUT</Link></li>
                        <li><Link to="/products" className="btn btn-link">PRODUCTS</Link></li>
                        <li><Link to="/testimonials" className="btn btn-link">TESTIMONIALS</Link></li>
                        <li><Link to="/subscriptions" className="btn btn-link">SUBSCRIPTIONS</Link></li>
                        <li><Link to="/contact" className="btn btn-link">CONTACT</Link></li>
                        {this.props.isUserAuthenticated ? (
                            <li><Link to="/my-account" className="btn btn-link">MY ACCOUNT</Link></li>
                        ) : (
                            <li><Link to="/login" className="btn btn-link">LOGIN</Link></li>
                        )}
                        <li><Link to="/cart" className="btn btn-link"><i className="fa fa-shopping-cart cart-icon"/></Link></li>
                    </ul>
                </div>
                <div className="mobile-icons">
                    <i className="burger fas fa-bars fa-2x" onClick={this.burgerToggle} />
                    <Link to="/cart" className="mobile-cart-icon"><i className="fa fa-shopping-cart cart-icon"/></Link>
                </div>
                <div className="nav-menu navNarrow">
                    <ul className="header narrowLinks">
                        <li><Link to="/" className="btn btn-link no-border" onClick={this.burgerToggle}>HOME</Link></li>
                        <li><Link to="/about" className="btn btn-link" onClick={this.burgerToggle}>ABOUT</Link></li>
                        <li><Link to="/products" className="btn btn-link" onClick={this.burgerToggle}>PRODUCTS</Link></li>
                        <li><Link to="/testimonials" className="btn btn-link" onClick={this.burgerToggle}>TESTIMONIALS</Link></li>
                        <li><Link to="/subscriptions" className="btn btn-link" onClick={this.burgerToggle}>SUBSCRIPTIONS</Link></li>
                        <li><Link to="/contact" className="btn btn-link" onClick={this.burgerToggle}>CONTACT</Link></li>
                        {this.props.isUserAuthenticated ? (
                            <li><Link to="/my-account" className="btn btn-link">MY ACCOUNT</Link></li>
                        ) : (
                            <li><Link to="/login" className="btn btn-link">LOGIN</Link></li>
                        )}
                        <li><Link to="/cart" className="btn btn-link" onClick={this.burgerToggle}>SHOPPING CART</Link></li>
                    </ul>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedHeader = connect(mapStateToProps)(Header);
export { connectedHeader as Header };