import React, { Component } from "react";
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import {TestimonialSlider} from "../TestimonialSlider";

const STATIC_IMAGES_PATH = process.env.STATIC_IMAGES_PATH;

class AudienceExpandedExample extends Component {
    render() {
        const colSize = 'col-md-' + (12 / this.props.numColumns);
        const imageFile = this.props.example.image ? this.props.example.image.filepath : "logo.png";
        return (
            <div className={"audience-example-block " + colSize}>
                <span className="audience-example-description" dangerouslySetInnerHTML={{__html: this.props.example.description}} />
                <img className="audience-example-image" src={STATIC_IMAGES_PATH + imageFile}/>
                <span className="audience-example-end-description" dangerouslySetInnerHTML={{__html: this.props.example.footnote}} />
            </div>
        )
    }
}

class AudienceExpandedExampleSection extends Component {
    render() {
        const numColumns = this.props.section.columns;
        
        // Split examples into rows
        let i,j,exampleRows = [];
        for (i=0, j=this.props.section.examples.length; i<j; i+=numColumns) {
            exampleRows.push(this.props.section.examples.slice(i,i+numColumns));
        }
        return (
            <div className="expanded-audience-section-content bnm-light-blue">
                {exampleRows.map(function(exampleRow, i){
                    return <div className="row" key={i}>
                        {exampleRow.map(function(example){
                            return (
                                <AudienceExpandedExample example={example} key={example.id} numColumns={numColumns} />
                            );
                        })}
                    </div>
                })}
                <div className="container expanded-section-end-description">
                    <span className="">{this.props.section.expanded_footnote}</span>
                </div>
            </div>
        )
    }
}

class AudienceExpandedSection extends Component {
    render() {
        return (
            <div className="expanded-audience-section" id={this.props.section.title} >
                <div className="expanded-audience-section-banner row bnm-dark-blue">
                    <div className="col-md-12">
                        <span className="expanded-section-title">{this.props.section.title}</span>
                        <span className="expanded-section-title-description allow-white-space">{this.props.section.expanded_description}</span>
                    </div>
                </div>
                <AudienceExpandedExampleSection section={this.props.section} />
            </div>
        )
    }
}

class AudienceSection extends Component {
    render() {
        const hideLink = this.props.section.examples.length > 0 ? "" : "hide-element";
        return (
            <div className={'row audience-section-container ' + this.props.classes}>
                <div className="container audience-section">
                    <span className="audience-section-title">{this.props.section.title}</span>
                    <span className="audience-section-description" dangerouslySetInnerHTML={{__html: this.props.section.description}} />
                    <a href={"#" + this.props.section.title} className={"btn btn-primary-dark audience-section-link "+ hideLink}>EXAMPLES</a>
                </div>
            </div>
        )
    }
}

class AudiencePage extends Component {
    render() {
        let hideStudentsButton = this.props.pageTitle === "For Students" ? "hide-element" : "";
        let hideTeachersButton = this.props.pageTitle === "For Teachers" ? "hide-element" : "";
        let hideSchoolsButton = this.props.pageTitle === "For Schools" ? "hide-element" : "";
        let hideTherapistsButton = this.props.pageTitle === "For Therapists" ? "hide-element" : "";
        return (
            <div className="audience-content">
                <TestimonialSlider testimonials={this.props.pageContent.testimonials}/>
                <div className="audience-page-title-container row bnm-dark-blue">
                    <div className="col-md-12">
                        <span className="audience-page-title">{this.props.pageTitle}</span>
                    </div>
                </div>
                {this.props.pageContent.sections.map(function(section, i){
                    let classes = "";
                    if (i % 2 === 1) {
                        classes = "bnm-light-gray"
                    }
                    return (
                        <AudienceSection section={section} key={section.id} classes={classes} />
                    );
                })}
                <div className="audience-link-row row bnm-white">
                    <div className="col-md-12">
                        <Link to={"/for-students"} className={"btn btn-link " + hideStudentsButton}><button className="btn btn-primary-dark audience-page-link">For Students</button></Link>
                        <Link to={"/for-teachers"} className={"btn btn-link " + hideTeachersButton}><button className="btn btn-primary-dark audience-page-link">For Teachers</button></Link>
                        <Link to={"/for-schools"} className={"btn btn-link " + hideSchoolsButton}><button className="btn btn-primary-dark audience-page-link">For Schools</button></Link>
                        <Link to={"/for-therapists"} className={"btn btn-link " + hideTherapistsButton}><button className="btn btn-primary-dark audience-page-link">For Therapists</button></Link>
                    </div>
                </div>
                {this.props.pageContent.sections.map(function(section, i){
                    if (section.examples.length === 0) return;
                    return (
                        <AudienceExpandedSection section={section} key={section.id} />
                    );
                })}
                <div className="audience-link-row row bnm-light-blue">
                    <div className="col-md-12">
                        <Link to={"/for-students"} className={"btn btn-link " + hideStudentsButton}><button className="btn btn-primary audience-page-link">For Students</button></Link>
                        <Link to={"/for-teachers"} className={"btn btn-link " + hideTeachersButton}><button className="btn btn-primary audience-page-link">For Teachers</button></Link>
                        <Link to={"/for-schools"} className={"btn btn-link " + hideSchoolsButton}><button className="btn btn-primary audience-page-link">For Schools</button></Link>
                        <Link to={"/for-therapists"} className={"btn btn-link " + hideTherapistsButton}><button className="btn btn-primary audience-page-link">For Therapists</button></Link>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedAudiencePage = connect(mapStateToProps)(AudiencePage);
export { connectedAudiencePage as AudiencePage };