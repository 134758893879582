import React, { Component } from "react";
import { connect } from 'react-redux';
import { contentService } from '../_services';
import {PageLoader} from "../PageLoader";

import "../../css/testimonials/testimonials.css";
import {ErrorPage} from "../ErrorPage";

class TestimonialBlock extends Component {
    render() {
        return (
            <div className={"container testimonial-row " + this.props.classes}>
                <div className="container">
                    <span className="testimonial-quote" dangerouslySetInnerHTML={{__html: this.props.testimonial.quote}} />
                    <span className="testimonial-author">{this.props.testimonial.author}</span>
                    <span className="testimonial-occupation">{this.props.testimonial.occupation}</span>
                </div>
            </div>
        )
    }
}

class TestimonialPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestFailed: false
        };
    }

    componentDidMount() {
        contentService.getPageContent('testimonials')
            .then(testimonials => {
                this.setState({
                    testimonials: testimonials
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    errorMessage: error
                })
            })
    }

    render() {
        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage}/>
            )
        }
        if (!this.state.testimonials) {
            return (
                <PageLoader />
            )
        }

        return (
            <div className="testimonials-content">
                {this.state.testimonials.map(function(testimonial, i){
                    var classes = "text-dark-blue";
                    if (i % 2 === 1) {
                        classes = "bnm-light-blue text-white secondary-link"
                    }
                    return (
                        <TestimonialBlock testimonial={testimonial} key={testimonial.id} classes={classes} />
                    );
                })}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedTestimonialPage = connect(mapStateToProps)(TestimonialPage);
export { connectedTestimonialPage as TestimonialPage };