import React, { Component } from "react";
import { connect } from 'react-redux';
import {Link} from "react-router-dom";

import "../../css/error/error.css";

class ErrorPage extends Component {
    render() {
        const iconClasses = this.props.iconClasses ? this.props.iconClasses : "far fa-frown";
        const hideButtonClass = this.props.showButton ? "" : "hide-element";
        return (
            <div className="row">
                <div className="page-error-container container">
                    <i className={"page-error-icon " + iconClasses}/>
                    <span>{this.props.errorMessage}</span>
                    <div className={"home-button " + hideButtonClass}>
                        <Link to="/">
                            <button className="btn btn-primary-dark">
                                <i className="fas fa-home"/>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedErrorPage= connect(mapStateToProps)(ErrorPage);
export { connectedErrorPage as ErrorPage };