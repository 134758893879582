import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { userService } from "../_services";

import { AboutPage } from "../AboutPage";
import { AccountPage } from '../AccountPage';
import { CheckoutPage } from '../CheckoutPage';
import { ContactPage } from "../ContactPage";
import CookieConsent from "react-cookie-consent";
import { ErrorPage } from "../ErrorPage";
import { Footer } from "../Footer";
import { ForgottenPasswordPage } from "../ForgottenPasswordPage";
import { Header } from "../Header";
import { HomePage } from "../HomePage";
import { LoginPage } from '../LoginPage';
import { SubscriptionManagementPage } from "../SubscriptionManagementPage";
import { ProductPage } from "../ProductPage";
import { ProductSubscriptionsPage } from "../ProductSubscriptionsPage";
import { ReceiptPage } from '../ReceiptPage';
import { RegisterPage } from '../RegisterPage';
import { ResetPasswordPage } from "../ResetPasswordPage";
import { SchoolsPage } from '../SchoolsPage';
import { ShoppingCartPage } from '../ShoppingCartPage';
import { StudentsPage } from '../StudentsPage';
import { SubscriptionsLandingPage } from "../SubscriptionsLandingPage";
import { TeachersPage } from '../TeachersPage';
import { TherapistsPage } from '../TherapistsPage';
import { TermsAndConditionsPage } from '../TermsAndConditionsPage'
import { TestimonialPage } from "../TestimonialPage";
import { VideoSubscriptionsPage } from "../VideoSubscriptionsPage";
import { WorksheetMakerPage } from "../WorksheetMakerPage/WorksheetMakerPage";

import 'react-toastify/dist/ReactToastify.min.css';
import "../../css/index.css";

class App extends React.Component {
    constructor(props) {
        super(props);

        const { dispatch } = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
        this.state = {
            isUserAuthenticated: userService.isUserAuthenticated()
        };
        this.updateUserAuthenticationState = this.updateUserAuthenticationState.bind(this);
    }

    updateUserAuthenticationState() {
        this.setState({
            isUserAuthenticated: userService.isUserAuthenticated()
        })
    }

    render() {
        const { alert } = this.props;
        return (
            <Router history={history}>
                <div className="app-container">
                    <Header isUserAuthenticated={this.state.isUserAuthenticated}/>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                        draggablePercent={60}
                        theme="colored"
                    />
                    <CookieConsent
                        location="bottom"
                        buttonText="I Understand"
                        cookieName="bnm-cookie-consent"
                        style={{ background: "#02254A" }}
                        buttonStyle={{ color: "#fff", "backgroundColor": "#286090", "borderColor": "#204d74", "borderRadius": "4px", fontSize: "18px" }}
                        buttonClasses="btn btn-primary-dark"
                        expires={150}
                    >
                        <span style={{ fontSize: "22px" }}>
                        This website uses cookies to enhance the user experience.{" "}
                        </span>
                    </CookieConsent>
                    <div className="content">
                        {alert.message &&
                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                        }

                        <div>
                            <Switch>
                                <Route exact path="/" component={HomePage} />
                                <PrivateRoute path="/my-account" component={AccountPage} updateUserAuthenticationState={this.updateUserAuthenticationState} />
                                <Route path="/products" component={ProductPage}/>
                                <Route path="/testimonials" component={TestimonialPage}/>
                                <Route path="/contact" component={ContactPage}/>
                                <Route path="/about" component={AboutPage}/>
                                <Route path="/login" render={(props) => (<LoginPage updateUserAuthenticationState={this.updateUserAuthenticationState} {...props}/>)} />
                                <Route path="/register" component={RegisterPage} />
                                <Route path="/forgot-password" component={ForgottenPasswordPage} />
                                <Route path="/reset-password" component={ResetPasswordPage} />
                                <Route path="/for-students" component={StudentsPage} />
                                <Route path="/for-teachers" component={TeachersPage} />
                                <Route path="/for-therapists" component={TherapistsPage} />
                                <Route path="/for-schools" component={SchoolsPage} />
                                <Route path="/terms-and-conditions" render={(props) => (<TermsAndConditionsPage defaultActiveKey={1} {...props}/>)} />
                                <Route path="/privacy-policy" render={(props) => (<TermsAndConditionsPage defaultActiveKey={2} {...props}/>)} />
                                <Route path="/cart" component={ShoppingCartPage} />
                                <PrivateRoute path="/subscriptions/products" component={ProductSubscriptionsPage} updateUserAuthenticationState={this.updateUserAuthenticationState} />
                                <PrivateRoute path="/subscriptions/videos" component={VideoSubscriptionsPage} updateUserAuthenticationState={this.updateUserAuthenticationState} />
                                <PrivateRoute path="/subscriptions/worksheet-maker" component={WorksheetMakerPage} updateUserAuthenticationState={this.updateUserAuthenticationState} />
                                <Route path="/subscriptions/" component={SubscriptionsLandingPage} />
                                <PrivateRoute path="/checkout" component={CheckoutPage} updateUserAuthenticationState={this.updateUserAuthenticationState} />
                                <PrivateRoute path="/receipt/:confirmationCode" component={ReceiptPage} updateUserAuthenticationState={this.updateUserAuthenticationState} />
                                <PrivateRoute path="/users/:userId/subscriptions/:userSubscriptionId/" component={SubscriptionManagementPage} updateUserAuthenticationState={this.updateUserAuthenticationState} />
                                <Route
                                    render={(routeProps) => (
                                        <ErrorPage {...routeProps} showButton={true} iconClasses="fas fa-search" errorMessage="Uh oh! This page doesn't exist." />
                                    )}
                                />
                            </Switch>
                        </div>
                    </div>
                    <Footer />
                </div>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
