import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userService } from "../_services";

import "../../css/account/account.css";

class UserOrderRow extends Component {
    render() {
        let reducedProducts = {};
        {this.props.order.products.map(function(product, i){
            if (reducedProducts.hasOwnProperty([product.title])) {
                reducedProducts[product.title]++
            }
            else {
                reducedProducts[product.title] = 1
            }
        })}
        return (
            <div className={"row order-history-row " + this.props.classes}>
                <div className="order-history-inner-row">
                    <div className="col-md-3 order-history-date">{this.props.order.created}</div>
                    <div className="col-md-2 order-history-amount">{this.props.order.amount}</div>
                    <div className="col-md-2 order-history-status">{this.props.order.status}</div>
                    <div className="col-md-5 order-history-items">
                        {Object.keys(reducedProducts).map(function(title,i) {
                            return (
                                <span className="order-history-product" key={i}>{reducedProducts[title]} x {title}</span>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

class AccountPage extends React.Component {
    constructor(props) {
        super(props);

        this.props.updateUserAuthenticationState();

        this.state = {
            hidePasswordChangeClass: '',
            showPasswordFieldsClass: 'hide-element',
            user: this.props.user,
            email: '',
            newPassword: '',
            oldPassword: '',
            userOrders: [],
            requestCompleted: false,
            showPasswordSuccess: false,
            showPasswordError: false,
            passwordErrorMessage: 'Error updating password.'
        };
        this.showPasswordChange = this.showPasswordChange.bind(this);
        this.hidePasswordChange = this.hidePasswordChange.bind(this);
        this.savePasswordChange = this.savePasswordChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

	showPasswordChange() {
		this.setState({
                hidePasswordChangeClass: 'hide-element',
                showPasswordFieldsClass: ''
            }
        )
	}

	hidePasswordChange() {
		this.setState({
                hidePasswordChangeClass: '',
                showPasswordFieldsClass: 'hide-element'
            }
        )
	}

	savePasswordChange() {
        const { user, newPassword, oldPassword } = this.state;
        userService.update({
            id: user.id,
            new_password: newPassword,
            old_password: oldPassword
        }).then(
            user => {
                this.setState({
                    user: user,
                    newPassword: '',
                    oldPassword: '',
                    showPasswordSuccess: true,
                    showPasswordError: false,
                });
                this.hidePasswordChange()
            },
            error => {
                this.setState({
                    showPasswordSuccess: false,
                    showPasswordError: true,
                    passwordErrorMessage: error
                })
            }
        )
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    componentDidMount() {
        userService.getUserOrders(this.state.user.id)
            .then(userOrders => {
                this.setState({
                    userOrders: userOrders,
                    requestCompleted: true
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    requestCompleted: true,
                    errorMessage: error
                })
            });
    }

    render() {
        const { user } = this.state;
        let passwordSuccessClass = this.state.showPasswordSuccess  ? '' : 'hide';
        let passwordErrorClass = this.state.showPasswordError  ? '' : 'hide';
        let loadingOrdersClass = this.state.requestCompleted  ? 'hide' : '';
        let emptyOrdersClass = !this.state.requestCompleted || this.state.userOrders.length > 0 ? 'hide' : '';
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-3 user-settings-container well">
                        <div className={"account-change-success " + passwordSuccessClass}>
                            <i className="fas fa-check-circle" />
                            <span className="account-change-success-message">Password successfully updated.</span>
                        </div>
                        <div className={"account-change-error " + passwordErrorClass}>
                            <i className="fas fa-exclamation-circle" />
                            <span className="account-change-error-message">{this.state.passwordErrorMessage}</span>
                        </div>
                        <span className="user-settings-label">Username/Email</span>
                        <span className={"user-settings-value"}>{user.username}</span>

                        <span className="user-settings-label">Password</span>
                        <span className={"user-settings-value " + this.state.hidePasswordChangeClass}>●●●●●●●●●●</span>
                        <a className={"user-settings-change-btn " + this.state.hidePasswordChangeClass} onClick={this.showPasswordChange}>Change</a>
                        <div className={this.state.showPasswordFieldsClass}>
                            <div className={'form-group'}>
                                <input type="password" className="form-control user-settings-password-input" name="oldPassword" placeholder="Old Password" value={this.state.oldPassword} onChange={this.handleChange}/>
                                <input type="password" className="form-control user-settings-password-input" name="newPassword" placeholder="New Password" value={this.state.newPassword} onChange={this.handleChange}/>
                            </div>
                            <button className="btn btn-primary-dark user-settings-save-btn" onClick={() => { this.savePasswordChange() }}>Save</button>
                            <a className={"user-settings-cancel-btn"} onClick={this.hidePasswordChange}>Cancel</a>
                        </div>
                        <div>
                            <Link to="/login" className="logout-link">
                                <button className="btn btn-primary-dark logout-button">
                                    Logout
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="order-history-container">
                            <h1>Order History</h1>
                            <div className="row order-history-header-row">
                                <div className="col-md-3 order-history-header">Date</div>
                                <div className="col-md-2 order-history-header">Amount</div>
                                <div className="col-md-2 order-history-header">Status</div>
                                <div className="col-md-5 order-history-header">Items</div>
                            </div>
                            <div className={"empty-orders-row row " + emptyOrdersClass}>
                                <span className="empty-orders-message">No orders to display.</span>
                            </div>
                            <div className={"loading-orders-row row " + loadingOrdersClass}>
                                <span className="loading-orders-message">Loading Orders ...</span>
                            </div>
                            {this.state.userOrders.map(function(order, i){
                                let classes = "";
                                if (i % 2 === 1) {
                                    classes = "bnm-light-gray"
                                }
                                return (
                                    <UserOrderRow order={order} key={order.id} classes={classes} />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedAccountPage = connect(mapStateToProps)(AccountPage);
export { connectedAccountPage as AccountPage };